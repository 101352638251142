import {
    createRouter,
    createWebHistory
} from "vue-router"
import Home from '../views/Home.vue'
import ContactUs from '../views/ContactUs.vue'
import NewsSchool from '../views/NewsSchool.vue'
import Library from '../views/library/Library.vue'
import Elementray from '../views/library/Elementray.vue'
import Kindergarten from '../views/library/Kindergarten.vue'
import Nurser from '../views/library/Nurser.vue'
import Jobs from '../views/family/Jobs.vue'
import Developer from '../views/family/Developer'
import ParentsSociety from '../views/family/ParentsSociety'
import TourSchool from '../views/submitting/TourSchool'
import RegisterFees from '../views/submitting/RegisterFees'
import AdmissionProcedures from '../views/submitting/AdmissionProcedures'
import SafetySecurity from '../views/aboute-us/SafetySecurity.vue'
import BuildSchool from '../views/aboute-us/BuildSchool.vue'
import OurVision from '../views/aboute-us/OurVision.vue'
import WelcomeMessage from '../views/aboute-us/WelcomeMessage.vue'
import PupilsAges from '../views/our-school/PupilsAges.vue'
import NurseryKindergarten from '../views/our-school/NurseryKindergarten.vue'
import Primary from '../views/our-school/Primary.vue'
import Steam from '../views/our-school/Steam.vue'
import ClubsActivities from '../views/our-school/ClubsActivities.vue'
import Nurser_A from '../views/library/Nurser-A.vue'
import Nurser_B from '../views/library/Nurser-B.vue'
import Nurser_C from '../views/library/Nurser-C.vue'
import KG1 from '../views/library/KG1.vue'
import KG2 from '../views/library/KG2.vue'
import GradeSixth from '../views/library/GradeSixth.vue'
import GradeFive from '../views/library/GradeFive.vue'
import GradeFour from '../views/library/GradeFour.vue'
import GradeOne from '../views/library/GradeOne.vue'
import GradeTwo from '../views/library/GradeTwo.vue'
import GradeThree from '../views/library/GradeThree.vue'
import AllPosts from '../views/AllPosts.vue'
import Parents from '../views/library/Parents.vue'
import Teachers from '../views/library/Teachers.vue'
import SteamPost from '../views/library/Steam.vue'


// import i18n from '../i18n'

const routes = [
    {
        path: "/",
        name: 'Home',
        component: Home
    },
    {
        path: "/ContactUs",
        name: 'ContactUs',
        component: ContactUs
    },
    {
        path: "/NewsSchool",
        name: 'NewsSchool',
        component: NewsSchool
    },
    {
        path: "/Library",
        name: 'Library',
        component: Library,
        meta: {
            reload: true,
        }

    },
    {
        path: "/Jobs",
        name: 'Jobs',
        component: Jobs
    }, {
        path: "/Developer",
        name: 'Developer',
        component: Developer
    }, {
        path: "/ParentsSociety",
        name: 'ParentsSociety',
        component: ParentsSociety
    }, {
        path: "/TourSchool",
        name: 'TourSchool',
        component: TourSchool
    }, {
        path: "/RegisterFees",
        name: 'RegisterFees',
        component: RegisterFees
    }, {
        path: "/AdmissionProcedures",
        name: 'AdmissionProcedures',
        component: AdmissionProcedures
    }, {
        path: "/SafetySecurity",
        name: 'SafetySecurity',
        component: SafetySecurity
    },
    {
        path: "/BuildSchool",
        name: 'BuildSchool',
        component: BuildSchool
    },
    {
        path: "/OurVision",
        name: 'OurVision',
        component: OurVision
    }, {
        path: "/WelcomeMessage",
        name: 'WelcomeMessage',
        component: WelcomeMessage
    },
    {
        path: "/PupilsAges",
        name: 'PupilsAges',
        component: PupilsAges
    }, {
        path: "/NurseryKindergarten",
        name: 'NurseryKindergarten',
        component: NurseryKindergarten
    },
    {
        path: "/Primary",
        name: 'Primary',
        component: Primary
    },
    {
        path: "/Steam",
        name: 'Steam',
        component: Steam
    },
    {
        path: "/ClubsActivities",
        name: 'ClubsActivities',
        component: ClubsActivities
    },
    {
        path: "/Elementray",
        name: 'Elementray',
        component: Elementray
    },
    {
        path: "/Kindergarten",
        name: 'Kindergarten',
        component: Kindergarten
    },
    {
        path: "/Nurser",
        name: 'Nurser',
        component: Nurser
    },
    {
        path: "/Nurser_A",
        name: 'Nurser_A',
        component: Nurser_A
    },
    {
        path: "/Nurser_B",
        name: 'Nurser_B',
        component: Nurser_B
    },
    {
        path: "/Nurser_C",
        name: 'Nurser_C',
        component: Nurser_C
    },
    {
        path: "/KG1",
        name: 'KG1',
        component: KG1
    },
    {
        path: "/KG2",
        name: 'KG2',
        component: KG2
    },
    {
        path: "/GradeOne",
        name: 'GradeOne',
        component: GradeOne
    },
    {
        path: "/GradeTwo",
        name: 'GradeTwo',
        component: GradeTwo
    },
    {
        path: "/GradeThree",
        name: 'GradeThree',
        component: GradeThree
    },
    {
        path: "/GradeFive",
        name: 'GradeFive',
        component: GradeFive
    },
    {
        path: "/GradeFour",
        name: 'GradeFour',
        component: GradeFour
    }, 
    {
        path: "/GradeSixth",
        name: 'GradeSixth',
        component: GradeSixth
    },{
        path: "/AllPosts",
        name: 'AllPosts',
        component: AllPosts
    },
    {
        path: "/SteamPost",
        name: 'SteamPost',
        component: SteamPost
    },
    {
        path: "/Parents",
        name: 'Parents',
        component: Parents
    },
    {
        path: "/Teachers",
        name: 'Teachers',
        component: Teachers
    }
]




const router = createRouter({
    scrollBehavior() {
        window.scrollTo(0, 0);
        // if (location.href.indexOf('reload')==-1)
        // {
        //    location.href=location.href+'?reload';
        // }
    },
    history: createWebHistory(),
    routes,
});

// router.beforeEach((to, from, next) => {

//     let language = to.params.lang;
//     if (!language) {
//         language = 'en'
//     }
//     i18n.locale = 'ar'
//     console.log("Active locale: ", i18n.locale)
//     next()
// })


export default router;
