<template>
    <div class="familySlide" id="familySlide">
        <div class="titleSection">{{ $t('titleSection.item4') }}</div>
        <v-container>
            <v-row>
                <v-col cols="12">
                    <ul class="d-flex flex-row sm-flex-column align-center">
                        <li class="contentImage">
                            <div class="d-grid grid-area">

                                <div class="img" v-for="(item, index) in data" :key="index">
                                    <img v-bind:src="item.coverImage" v-if="item" />
                                </div>

                            </div>
                            <div class="controls-slide align-center d-flex">
                                <div class="prev" @click="prevFamily"><img src="image/prev2.svg" /></div>
                                <div class="next" @click="nexFamily"><img src="image/next2.svg" /></div>
                            </div>
                        </li>
                        <li class="contentText">
                            <ul class="d-flex flex-column">
                                <li class="d-grid">
                                    <div v-for="item in data" :key="item.id" style="display: grid;grid-area: -1/1;">
                                        <h2 class="titleFamily" v-if="item">
                                            {{ this.$i18n.locale == 'ar' ? item.name : item.en_name }}
                                        </h2>
                                    </div>

                                </li>
                                <li class="d-grid flex-column">
                                    <div v-for="item in data" :key="item.id" style="display: grid;grid-area: -1/1;">
                                        <h1 class="h1" v-if="item"> {{ 
                                        this.$i18n.locale == 'ar' ?
                                                item.subDescription : item.en_subDescription
                                        }}
                                        </h1>
                                        <div class="lineTitle"></div>
                                    </div>
                                </li>

                                <li class="d-grid">
                                    <div v-for="item in data" :key="item.id" style="display: grid;grid-area: -1/1;">
                                        <p class="text" v-if="item">
                                            {{ this.$i18n.locale == 'ar' ? item.description : item.en_description }}
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import axios from 'axios';
    export default {
        components: {},
        data() {
            return {
                dataIsReady: false,
                data: []
            }
        },
        methods: {
            async getData() {
                await axios.get('https://faya.dev/apiProjections/public/api/posts?storeId=3&type=position')
                    .then((res) => {
                        this.data = res.data.data;
                        this.dataIsReady = true;
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            },

            //slide2
            autoplay() {

                let img = document.querySelectorAll(".img");
                let infoText = document.querySelectorAll(".text");
                let title = document.querySelectorAll(".titleFamily");
                let h1 = document.querySelectorAll(".h1");

                let indexImg = 0;
                let indexText = 0;
                let indexTitle = 0;
                let index_h1 = 0;

                img[indexImg].classList.add('imgActive');
                infoText[indexText].classList.add('textActive');
                title[indexTitle].classList.add('titleActive');
                h1[index_h1].classList.add('activeH1');

                setInterval(function () {

                    img.forEach((item, index) => {
                        if (item.classList.contains('imgActive')) {
                            indexImg = index;
                            indexText = index;
                            indexTitle = index;
                            index_h1 = index;
                        }
                    });
                    img[indexImg].classList.remove('imgActive');
                    infoText[indexText].classList.remove('textActive');
                    title[indexTitle].classList.remove('titleActive');
                    h1[index_h1].classList.remove('activeH1');

                    indexImg = (indexImg + 1) % img.length;
                    indexText = (indexText + 1) % infoText.length;
                    indexTitle = (indexTitle + 1) % title.length;
                    index_h1 = (index_h1 + 1) % h1.length;

                    img[indexImg].classList.add('imgActive');
                    infoText[indexText].classList.add('textActive');
                    title[indexTitle].classList.add('titleActive');
                    h1[index_h1].classList.add('activeH1');

                }, 5000);

            },


            nexFamily() {
                let img = document.querySelectorAll(".img");
                let infoText = document.querySelectorAll(".text");
                let title = document.querySelectorAll(".titleFamily");
                let h1 = document.querySelectorAll(".h1");

                let indexImg = 0;
                let indexText = 0;
                let indexTitle = 0;
                let index_h1 = 0;

                img.forEach((item, index) => {
                    if (item.classList.contains('imgActive')) {
                        indexImg = index;
                        indexText = index;
                        indexTitle = index;
                        index_h1 = index;
                    }
                });

                img[indexImg].classList.remove('imgActive');
                infoText[indexText].classList.remove('textActive');
                title[indexTitle].classList.remove('titleActive');
                h1[index_h1].classList.remove('activeH1');

                indexImg = (indexImg + 1) % img.length;
                indexText = (indexText + 1) % infoText.length;
                indexTitle = (indexTitle + 1) % title.length;
                index_h1 = (index_h1 + 1) % h1.length;


                img[indexImg].classList.add('imgActive');
                infoText[indexText].classList.add('textActive');
                title[indexTitle].classList.add('titleActive');
                h1[index_h1].classList.add('activeH1');

            },

            prevFamily() {

                let img = document.querySelectorAll(".img");
                let infoText = document.querySelectorAll(".text");
                let title = document.querySelectorAll(".titleFamily");
                let h1 = document.querySelectorAll(".h1");

                let indexImg = 0;
                let indexText = 0;
                let indexTitle = 0;
                let index_h1 = 0;

                img.forEach((item, index) => {
                    if (item.classList.contains('imgActive')) {
                        indexImg = index;
                        indexText = index;
                        indexTitle = index;
                        index_h1 = index;
                    }
                });

                img[indexImg].classList.remove('imgActive');
                infoText[indexText].classList.remove('textActive');
                title[indexTitle].classList.remove('titleActive');
                h1[index_h1].classList.remove('activeH1');

                indexImg = (indexImg - 1 + img.length) % img.length;
                indexText = (indexText - 1 + infoText.length) % infoText.length;
                indexTitle = (indexTitle - 1 + title.length) % title.length;
                index_h1 = (index_h1 - 1 + h1.length) % h1.length;


                img[indexImg].classList.add('imgActive');
                infoText[indexText].classList.add('textActive');
                title[indexTitle].classList.add('titleActive');
                h1[index_h1].classList.add('activeH1');



            },



        },

        async mounted() {
            await this.getData();
            this.autoplay();
        }
    }

</script>








