<template>
  <v-app id="en">
    <nav-bar class="navAr"></nav-bar>
    <v-main>
    <div id="dark">
    <i class="indicator"></i>
    </div>
    <router-view  v-slot="{ Component }">
      <transition name="slide-fade">
        <component :is="Component" />
      </transition>
      </router-view>
    </v-main>
   <FooterPro class="footerAr"></FooterPro>
  </v-app>
</template>
<script>
import NavBar from "@/components/navbar/NavBar.vue";
import FooterPro from "@/components/Footer.vue";

export default {
  name: "App",
  data() {
    return {
    }
},
  components: {
    NavBar,
    FooterPro
  },
  mounted() {

const darks = document.getElementById('app');
const toggleActive = document.getElementById('dark');

toggleActive.onclick = function() {
    toggleActive.classList.toggle('light');
    darks.classList.toggle('appDark');
}
  }
};
</script>

