import { createApp} from 'vue'
import App from './App.vue'
import router from './router/index'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import "./assets/scss/index.scss";
import i18n from './i18n'
import NwImg from 'nw-img-vue';
import gtag from "vue-gtag-next";

loadFonts()
const app = createApp(App);
app.use(router);
app.use(i18n);
app.use(vuetify);
app.mount('#app');
app.use(NwImg);
app.use(gtag, {
    property: {
        id:  'G-ZS4LE1TEY5'}
  });


