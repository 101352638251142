export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ar"])},
  "nav": {
    "faContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إتصل"])},
    "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعرض"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفعاليات"])},
    "dropContact": {
      "visit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موقعنا"])},
      "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقديم"])},
      "enquiy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أستفسار"])}
    },
    "Menu": {
      "menuName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القائمة"])},
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية"])},
      "aboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن"])},
      "schooLife": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدرستنا"])},
      "admissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقديم"])},
      "ourCommunity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عائلتنا"])},
      "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إتصل بنا"])},
      "visit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موقعنا"])},
      "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقديم"])},
      "enquiy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أستفسار"])}
    },
    "subMenu": {
      "aboutUs": {
        "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسالة الترحيب"])},
        "ourVision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رؤيتنا"])},
        "campusFacilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مبنى المدرسة"])},
        "securityWellbeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأمن والسلامة"])}
      },
      "schoolLife": {
        "pupilsAges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعمار التلاميذ"])},
        "kinderGarten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحضانة ورياض الأطفال"])},
        "elementary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الابتدائية"])},
        "steam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منهج STEAM"])},
        "clubsActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاندية والانشطة"])}
      },
      "admissions": {
        "admissionsProcess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجراءات القبول"])},
        "admissionsFees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسوم القبول"])},
        "schoolTours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جولة حول المدرسة"])}
      },
      "ourCommunity": {
        "parentsCommunity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الآباء والمجتمع"])},
        "development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التطوير"])},
        "jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوظائف الشاغرة"])}
      }
    }
  },
  "footer": {
    "visit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موقعنا"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقديم"])},
    "enquiy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أستفسار"])},
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إتصل بنا"])},
    "follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تابعونا على"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" بغداد - العراق / زيونة - خلف مول زيونة"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشروط والخصوصية"])},
    "sitemap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض على الخريطة"])}
  },
  "whereNext": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوجهة التالية"])},
    "parentsCommunity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أولياء الأمور"])},
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إتصل بنا"])},
    "visitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الزائرين"])},
    "elementary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأبتدائية"])},
    "clubsActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأندية والانشطة"])}
  },
  "contactUs": {
    "sectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أتصل بنا"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفحة الرئيسة"])},
    "titleContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الأتصال"])},
    "info": {
      "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد ألالكتروني "])},
      "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضغط هنا لمزيد من العلومات"])},
      "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان :"])},
      "item6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بغداد - زيونة -خلف مول زيونة"])},
      "item7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هاتف المكتب الرئيسي"])}
    }
  },
  "abouteUs": {
    "welcomeMessage": {
      "subtitlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسالة الترحيب"])},
      "linkList": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفحة الرئيسية"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسالة الترحيب"])}
      },
      "contentPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" أهلا وسهلا بكم في المدرسة الملكية حيث الاستثمار الأفضل في مستقبل اولادكم ."])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدرسة فريدة من نوعها، تقدم تعليم عالي الجودة للتلاميذ الذين تتراوح أعمارهم من 2-12 سنة من خلال خدمات تعليمية استثنائية. تهدف المدرسة الملكية في بغداد إلى تنمية القدرات .الفكرية للأطفال على مدى 10 سنوات ، وإعدادهم للمرحلة التالية من تعليمهم"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رحلة من عشرة سنين معنا، تتيح للتلاميذ فرصة تعلم مناهج متميزة على المستوى العالمي، من شأنها تنمية مهارات التلميذ. مثال على ذلك منهج الذي يصنع تجربة واقعية تتكامل فيها دروس (العلوم، التكنولوجيا، الهندسة، الفن، و الرياضيات) مستندين فيه إلى لغة التواصل العالمية (اللغة الانجليزية). حيث يتعلم التلاميذ مهارة الحوار مع مختلف ثقافات العالم، داعمين .الجانب النفسي للتلاميذ لضمان استمتاعهم وسعادتهم في رحلتنا الدراسية"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ولأجل توفير مستوى تعليمي عال ومتطور يتطلب الأمر منشآت ومرافق تعليمية متطورة ومتقدمة، وهو شيء نفخر بتقديمه في المدرسة الملكية في بغداد. إن بيئتنا المريحة والآمنة .والمجهزة جيدًا تتيح للتلاميذ التركيز على دراستهم وتحقيق إمكاناتهم"])},
        "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تشجيع التلاميذ على التفوق ضمن منهج واسع، بدعم من معلمين مؤهلين تأهيلا عاليا. يقومون بتحقيق إمكاناتهم في بيئة دراسية مريحة مليئة بمرافق من أعلى المعايير الدولية. تُستخدم اللغة الإنجليزية بشكل يومي في المدرسة الملكية - لا يتم تدريسها للأغراض التعليمية .فقط ولكن لتوسيع فرص الحياة التي يستطيع تلاميذنا"])},
        "item6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" سيُطلب من المرشحين المختارين إجراء اختبارات ذهنية ومهارية ، والمشاركة في مقابلة (إما عبر الإنترنت أو شخصيًا) - وإذا نجحوا - سيُطلب من المرشح تقديم درس كامل مجال أماماللجنة"])},
        "item7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وهر مهمتنا هو إعداد تلاميذ لمستقبل زاهر. نسعى لتقديم أساس رائع للتعليم الثانوي ونلتزم بإلهام الجيل القادم للعمل بروح الفريق الواحد، إن استخدام التلميذ لمعرفته يجعله قادرا على إحداث التطوير. كل تلميذ قادر على تحقيق كامل إمكاناته من خلال دعمنا المتخصص، وسوف .يتخرج التلاميذ من المدرسة الملكية كأفراد واثقين ومستعدين لمواجهة"])}
      },
      "titlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن"])},
      "linkPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسالة الترحيب"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رؤيتنا"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مبنى المدرسة"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الامن و السلامة"])}
      },
      "otherLink": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواضيع ذات الصلة"])},
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القبول"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الروضة"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإبتدائية"])}
      }
    },
    "safetySecurity": {
      "subtitlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الامن و السلامة"])},
      "linkList": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفحة الرئيسية"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الامن و السلامة"])}
      },
      "contentPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعتبر سلامة تلاميذنا ذات أهمية قصوى بالنسبة لنا في المدرسة الملكية، ويعكس ذلك عددًا من تدابير الأمن والوقاية  ."])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لضمان بقاء كل تلميذ وموظف بأمان في المدرسة، توجد منظومة كاميرات مراقبة مما يوفر  تغطية كاملة في جميع أنحاء المدرسة، بتصوير عالي الدقة. يتم تسجيل لوحات ترخيص السيارات لضمان مغادرة التلاميذ في نهاية اليوم مع أفراد الأسرة المعنيين، كما يتم تسجيل بيانات .الأشخاص القادمين لاصطحاب التلاميذ لضمان وصول الأطفال مع أشخاص موثوقين"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توفر المدرسة حراس أمن على مدار الساعة طوال أيام الأسبوع على استعداد للتعامل مع أي .حادث من أجل الحفاظ على بيئة آمنة للمدرسة"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تضمن سياسات السلوك أن توفر مدرستنا جوًا ترحيبيًا للجميع. لا يتم التسامح مع التنمر والتمييز .في إساءة المعاملة من اتجاه أي شخص في المدرسة"])},
        "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لضمان قدرتنا على تلبية احتياجات كل طفل على حدة في أوقات الوجبات بغض النظر عن المتطلبات الغذائية، سيتم إصدار بطاقات للآباء ليقدموا معلومات عن الحساسية والتوصيات  لأطفال الحضانة والروضة. تسلم البطاقات لموظفي تقديم الطعام لضمان حصول التلاميذ على .خيارات الطعام المناسبة"])},
        "item6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في الظروف الحالية، نحن ملتزمون بتنفيذ تدابير الوقاية من كوفد-19 وذلك بتوفير معقمات اليدين واستخدام أنظمة التطهير وفرض ارتداء الكمامات والتباعد الاجتماعي. إضافة الى ان جميع الكادر العامل في المدرسة ملتزمين بأخذ اللقاح الخاص بكوفيد-19 وكذلك نرجو من جميع .التلاميذ والموظفين والزوار الالتزام بهذه الإرشادات من أجل سلامة الجميع"])},
        "item7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة الى ذلك المدرسة مزودة بوحدة طبابة متكاملة ومجهزة خصيصا للمدرسة مع طبيب ومساعدة جاهزين طيلة ساعات الدوام الرسمي ومستعدين لأي حالة. إضافة لإجراء فحص دوري .للتلاميذ للتأكد من سلامتهم الصحية وتزويد كل تلميذ بتقرير دوري عن وضعه الصحي"])}
      },
      "titlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن"])},
      "linkPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسالة الترحيب"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رؤيتنا"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مبنى المدرسة"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الامن و السلامة"])}
      },
      "otherLink": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواضيع ذات الصلة"])},
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إتصل بنا"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التطوير"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوظائف الشاغرة"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدرستنا"])}
      }
    },
    "OurVision": {
      "subtitlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رؤيتنا"])},
      "linkList": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفحة الرئيسية"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رؤيتنا"])}
      },
      "contentPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إنشاء المدرسة الملكية من قبل فريق من الخبراء من جميع أنحاء العالم، حيث قاموا بتحديد الفجوة الموجودة في مجال التعليم واجتمعوا لسدها   ."])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قام المختصون التربويون المتحمسون لدينا بدمج معرفتهم بالأدوات المبتكرة لتقديم مستقبل .مشرق للجيل القادم ، يسعى لتحقيق رؤيتنا في"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرؤية :"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تتمثل رؤيتنا في توفير تعليم جيد يمكّن التلاميذ من اتباع مسارهم الخاص وإعدادهم للتغلب على تحديات الغد. شعارنا 'استثمر في مستقبل أطفالك' هو اختصار يلخص صفات المدرسة"])},
        "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القيم :"])},
        "item6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" لضمان تنشئة جيلٍ قادرٍ على التكيف مع عالم سريع التغير وإحداث التطور فيه، فإننا ندعم نهجنا التعليمي بخمس قيم : "])},
        "item7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإحساس بالانتماء للمجتمع"])},
        "item8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النزاهة"])},
        "item9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القيادة"])},
        "item10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحكمة"])},
        "item11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاحترام"])},
        "item12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" بهذه المبادئ التمكينية، لن يخشى خريجونا التغيير ويواصلون بثقة الابتكار ويقودون المجتمع .بنجاح وأمانة"])}
      },
      "titlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن"])},
      "linkPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسالة الترحيب"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رؤيتنا"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مبنى المدرسة"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الامن و السلامة"])}
      },
      "otherLink": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواضيع ذات الصلة"])},
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الروضة"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإبتدائية"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القبول"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التطوير"])}
      }
    },
    "buildSchool": {
      "subtitlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مبنى المدرسة"])},
      "linkList": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفحة الرئيسية"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مبنى المدرسة"])}
      },
      "contentPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" بناية المدرسة مصممة بطريقة تراعي اقصى درجة الراحة والرفاهية والأمان للتلاميذ .ومن جميع النواحي "])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فعلى سبيل المثال توفر النوافذ والأبواب الزجاجية الكبيرة ضوءًا طبيعيًا وفيرًا للمساعدة في التركيز والأداء الأكاديمي، باستخدام زجاج آمن عالي القوة يقوم بتصفية الأشعة فوق البنفسجية .Alumni ومعزول للحفاظ على درجات حرارة مريحة مصنوعة من قبل فريق مختص من شركة"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تصميم الإضاءة الإضافية وتركيبها من قبل خبراء شركة بما يتوافق مع أعلى المعايير."])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تم تزويد الصفوف والقاعات وكافة مرافق المدرسة بأنظمة تكييف للحفاظ على بيئة .مريحة في كافة فصول السنة"])},
        "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تصميم وتجهيز الأرضيات من قبل شركة GerFlor لتكون مقاومة للبكتيريا والانزلاق والصدى .والصدمات وعازلة للصوت"])},
        "item6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الممرات الخارجية وساحات الفناء مغطاة بعشب صناعي مقاوم للحريق ومضاد للبكتيريا ومضاد .للحساسية، صديق للبيئة وخالي من السمية"])},
        "item7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تم تجهيز المدرسة بنظام إنذار وإطفاء حريق شامل تغطي كافة مرافق المدرسة طورتها شركة ."])},
        "item8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم نصب وتركيب كاميرات المراقبة الصوتية والمرئية في جميع أنحاء المدرسة والتي تعمل على .مدار الساعة طوال أيام الأسبوع باستخدام تقنية الكاميرا عالية"])},
        "item9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["علاوة على ذلك ، لدينا مساحات تعليمية مرنة مثل القاعة الكبيرة المثالية لمجموعة من الاستخدامات بما في ذلك الرياضات الداخلية والمسرحيات والأحداث والفعاليات والمناسبات الأخرى إضافة إلى قاعة ألعاب داخلية أخرى لتلبية كافة احتياجات التلاميذ ."])},
        "item10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" المدرسة مزودة بوحدة طبابة متكاملة ومجهزة خصيصا للمدرسة مع طبيب ومساعدة جاهزين طيلة ساعات الدوام الرسمي مستعدين لأي حالة. إضافة الى ذلك إجراء فحص دوري للتلاميذ .للتأكد من سلامتهم الصحية"])},
        "item11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ونحن فخورون أيضًا بأن نكون أول مؤسسة تعليمية في العراق توفر أجهزة تنقية الهواء من السويدية ، مما يوفر من تنقية الهواء في الأماكن التي يشغلها أطفالنا الصغار بشكل Blueair .أكبر من خلال إزالة الغبار وحبوب اللقاح ، البكتيريا والفيروسات من الجو"])},
        "item12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" COVID19 لضمان بقاء منشآتنا متميزة وآمنة تتوافق مع المعايير الدولية قامت المدرسة الملكية بالتعاون مع مجموعة شركات من أفضل مقدمي الخدمات التعليمية والخدمات المرتبطة بها في جميع أنحاء العالم، بما في ذلك شركة الرائدة في تصميم المدارس حيث قامت بتصميم المدرسة بطريقة تسمح بتهوية جيدة وتبديل الهواء وتمتع التلاميذ بمساحة  وفيرة تساعد على الوقاية من انتشار الأمراض ومن ضمنها"])},
        "item13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تضمن الكافتيريا الكبيرة والحديثة تزويد التلاميذ بالتغذية الصحية ليومهم وتوفير مساحات مريحة .لقضاء أوقات الاستراحة"])},
        "item14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مواقف السيارات الواسعة المتعددة تسمح بتجميع وتوصيل التلاميذ دون أي ضغوط وبطريقة .آمنة"])},
        "item15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تلعب التكنولوجيا دورًا أساسيًا في عملنا ، مع فصول دراسية مزودة بشاشات وتطبيقات ذكية .لتعليم له صلة بعصرنا"])},
        "item16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستخدمين النظام الإداري العالمي الذي يخلق حلقة تواصل خاصة بين الآباء .والكادر والتلاميذ وكافة مفاصل العملية التعليمية في آن"])},
        "item17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تجتمع كل هذه المميزات المدروسة بعناية فائقة لإنشاء مكان حديث وهو المدرسة الملكية في .بغداد. نأمل أن نلتقي بكم و نأخذكم في جولة حول مبنى المدرسة الرائع قريبًا"])}
      },
      "titlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن"])},
      "linkPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسالة الترحيب"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رؤيتنا"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مبنى المدرسة"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الامن و السلامة"])}
      },
      "otherLink": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواضيع ذات الصلة"])},
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدرستنا"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القبول"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STEAM منهج"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأندية والأنشطة"])}
      }
    }
  },
  "family": {
    "developer": {
      "subtitlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التطوير"])},
      "linkList": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفحة الرئيسية"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عائلتنا"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التطوير"])}
      },
      "contentPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" التطوير المستمر هو شيء نكرس أنفسنا له في المدرسة الملكية ."])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" يشمل التطوير المهارات الشخصية للطلاب والتحسين المستمر لمدرستنا. نسعى جاهدين للتأكد من أن كل طفل تحت رعايتنا يحقق أفضل ما لديه من أنشطته وقدراته من خلال استخدام الأدوات والأساليب وطرق التدريس الفعالة."])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتم مراجعة جميع الأحداث والأنشطة المدرسية بشكل منتظم لضمان استمرارنا في إحداث تأثير فعال على التعلم مع الحفاظ على متعة التعلم والمشاركة."])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" المستشار التربوي هو عضو رئيسي في فريقنا الذي يراقب سلوك الطلاب وأنشطتهم اليومية. يتم إصدار تقارير دورية عن سلوك التلاميذ إلى أولياء الأمور ويتم تطوير استراتيجيات متخصصة لتنمية شخصية التلاميذ ومهاراتهم الاجتماعية."])}
      },
      "titlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عائلتنا"])},
      "linkPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الآباء والمجتمع "])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التطوير"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوظائف الشاغرة"])}
      },
      "otherLink": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواضيع ذات الصلة"])},
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أولياء الأمور"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رؤيتنا"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مبنى المدرسة"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الامن و السلامة"])}
      }
    },
    "jobs": {
      "subtitlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوظائف الشاغرة"])},
      "linkList": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفحة الرئيسية"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عائلتنا"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوظائف الشاغرة"])}
      },
      "contentPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" هل أنت محترف وتتطلع إلى إحداث فرق في تعليم الجيل القادم؟ إذا كان الأمر .كذلك، يسعدنا التواصل معك"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تتمثل رؤية المدرسة الملكية في توفير تعليم جيد يمكّن الأطفال من اتباع مسارهم الخاص وإعدادهم للتغلب على تحديات الغد. هذا شيء لا يمكننا تحقيقه بدون فريق من الموظفين .المتفانين"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تقدم للعمل معنا !"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" يجب على المتقدمين الذين يعيشون في العراق ببساطة تقديم سيرتهم الذاتية إلى الإدارة .شخصيًا"])},
        "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" يجب على المتقدمين الذين يعيشون خارج العراق تقديم سيرتهم الذاتية عبر الإنترنت ، وستكون .المقابلة الخاصة بهم عبر الانترنت أيضًا"])},
        "item6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" سيُطلب من المرشحين المختارين إجراء اختبارات ذهنية ومهارية ، والمشاركة في مقابلة (إما عبر الإنترنت أو شخصيًا) - وإذا نجحوا - سيُطلب من المرشح تقديم درس كامل مجال أمام اللجنة .المختصة كدليل على قدراتهم التعليمية"])},
        "item7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" إذا كنت مهتم للعمل معنا يسعدنا أن نسمع منك  ."])}
      },
      "titlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عائلتنا"])},
      "linkPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الآباء والمجتمع "])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التطوير"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوظائف الشاغرة"])}
      },
      "otherLink": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواضيع ذات الصلة"])},
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسالة الترحيب"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدرستنا"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رؤيتنا"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الامن و السلامة"])}
      }
    },
    "parentsSociety": {
      "subtitlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الآباء والمجتمع"])},
      "linkList": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفحة الرئيسية"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عائلتنا"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الآباء والمجتمع"])}
      },
      "contentPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحظى مشاركة الوالدين بتقدير كبير في المدرسة الملكية في بغداد - نشجع الآباء .على المشاركة في رحلة أطفالهم التعليمية والمشاركة في مجتمعنا"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ولدعم ذلك، أنشأنا منصة للآباء لإبقائهم على دراية بأنشطة وفعاليات أطفالهم. من خلال .الاجتماعات بين أولياء الأمور والمعلمين والزيارات المنزلية من قبل الموظفين المختصين"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لدينا برنامج مكثف لتمكين الاتصال مع أولياء الأمور طوال الوقت الذي يقضيه أطفالهم معنا, وذلك باستخدام أحدث البرامج التكنولوجية المتطورة, التي تسجل و تتابع سلوك و كافة أنشطة .التلاميذ اليومية, وتشاركها مع الآباء من خلال الأجهزة الذكية وتطبيقاتها"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يساعد هذا في تحديد أي مشاكل في وقت مبكر، مما يعود بالفائدة على جميع المعنيين وتحقيق رؤيتنا في توفير تعليم جيد ومتطور وبما يساهم في بناء وتنمية شخصية الأطفال بصورة صحيحة  ."])}
      },
      "titlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عائلتنا"])},
      "linkPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الآباء والمجتمع "])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التطوير"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوظائف الشاغرة"])}
      },
      "otherLink": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواضيع ذات الصلة"])},
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأندية والأنشطة"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التطوير"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوظائف الشاغرة"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رؤيتنا"])}
      }
    }
  },
  "ourSchool": {
    "steam": {
      "subtitlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منهج STEAM"])},
      "linkList": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفحة الرئيسية"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدرستنا"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منهج STEAM"])}
      },
      "contentPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" إن منهاج STEAM منهاج ديناميكي يجمع بين العلوم والتكنولوجيا والهندسة والفنون .والرياضيات - يمكّن الأطفال منذ الصغر من الازدهار في عالم اليوم سريع التغير"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقوم المدرسة الملكية بتدريس منهاج STEAM باستخدام تكنولوجيا Skriware لتقديم منهج عملي  للتعلم الذي ينفذ باستخدام أدوات و مواد تدريس حديثة."])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكّن منهاج STEAM التلاميذ من اكتساب المعرفة والمهارات وإظهار قدراتهم بطريقة علمية و عملية. لا يدعم هذا المنهاج التحصيل الأكاديمي فحسب بل يعزز أيضًا العلاقات بين  التلاميذ ونتيجة لذلك تتطور المهارات الاجتماعية لديه."])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خلال الفصل الدراسي يقوم الأطفال باستخدام برامج متخصصة وتكنولوجيا حديثة للمشاركة في مجموعة من الأنشطة من الطباعة ثلاثية الأبعاد إلى الروبوتات والبرمجة. من خلال هذه الانشطة يصبحون مبتكرين مع تعطش للمعرفة و حماس وشغف للتعلم و الذي سيحملونه معهم طوال الحياة."])}
      },
      "titlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدرستنا"])},
      "linkPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعمار التلاميذ"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحضانة ورياض الأطفال"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الابتدائية"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منهج STEAM"])},
        "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاندية والانشطة"])}
      },
      "otherLink": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواضيع ذات الصلة"])},
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التطوير"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإبتدائية"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الروضة"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدرستنا"])}
      }
    },
    "pupilsAges": {
      "subtitlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعمار التلاميذ"])},
      "linkList": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفحة الرئيسية"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدرستنا"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعمار التلاميذ"])}
      },
      "contentPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" المراحل:"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" المدرسة الملكية مقسمة إلى ثلاث مراحل رئيسية   : "])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الحضانة :"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" من 2 الى 4 سنوات"])},
        "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" رياض الاطفال :"])},
        "item6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" من 4 الى 6 سنوات"])},
        "item7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الابتدائية:"])},
        "item8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" من 6 سنوات الى 12 سنة"])},
        "item9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" كل فئة من هذه الفئات العمرية يقودها كادر تدريسي متفاني ومتخصص يقوم بالتدريس على مستوى مناسب للفئة العمرية المحددة ،وبما يعزز قدرات التلاميذ في كل خطوة من مراحل .نموهم"])},
        "item10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" المناهج :"])},
        "item11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" نحن نقدم نوعاً فريدًا من المناهج غير موجود في أي مكان آخر ، حيث نجمع بين المنهج الحكومي ومنهج الأسترالي ومنهج Oxford يتم استخدامها بشكل علمي مدروس في مجالات ومواضيع مختلفة لتوفير تجربة تعليمية فريدة وفعالة ."])},
        "item12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" يُشكِل المنهج الحكومي أساس نهجنا في التدريس  ."])},
        "item13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" نتبع منهج Oxford في العلوم والرياضيات ودراسات الكمبيوتر  ."])},
        "item14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يُستَخدم STEAM لتوجيه أنشطة الإثراء في العلوم والتكنولوجيا والهندسة والفنون والرياضيات ."])},
        "item15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتم استخدام اللغة الإنكليزية كلغة أساسية في التدريس كونها لغة التواصل والحوار العالمية الاولى اضافة الى التركيز على اللغة العربية في مادتي التربية الإسلامية والعربي   ."])}
      },
      "titlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدرستنا"])},
      "linkPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعمار التلاميذ"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحضانة ورياض الأطفال"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الابتدائية"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منهج STEAM"])},
        "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاندية والانشطة"])}
      },
      "otherLink": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواضيع ذات الصلة"])},
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الروضة"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإبتدائية"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسوم"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جولة حول المدرسة"])}
      }
    },
    "primary": {
      "subtitlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الابتدائية"])},
      "linkList": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفحة الرئيسية"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدرستنا"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الابتدائية"])}
      },
      "contentPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" خلال سنوات الدراسة الابتدائية الست التي يقضيها التلاميذ معنا ، نعزز المهارات .والإنجازات الأكاديمية مع الاستمرار في المهارات الشخصية الأساسية للتلاميذ"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في السنوات الابتدائية، نتبع المنهاج الحكومي و مناهج إثرائية منتقاة بدقة لتوفير تعليم عالي .الجودة وشامل"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتم تدريس مناهج جنبا الى جنب مع المنهاج الحكومي مع المناهج .الحكومي"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" يتم تدريس منهاج STEAM و Oxford للرياضيات والعلوم والحاسوب من الصف الأول الى الصف السادس  ."])},
        "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" يتم تدريس STEAM (العلوم، التكنولوجيا، الهندسة، الفن، الرياضيات) من الروضة KG2 الى الصف .السادس"])}
      },
      "titlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدرستنا"])},
      "linkPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعمار التلاميذ"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحضانة ورياض الأطفال"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الابتدائية"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منهج STEAM"])},
        "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاندية والانشطة"])}
      },
      "otherLink": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواضيع ذات الصلة"])},
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإبتدائية"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدرستنا"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوظائف الشاغرة"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إتصل بنا"])}
      }
    },
    "nurseryKindergarten": {
      "subtitlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحضانة ورياض الأطفال"])},
      "linkList": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفحة الرئيسية"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدرستنا"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحضانة ورياض الأطفال"])}
      },
      "contentPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" توفر برامج الحضانة ورياض الأطفال لدينا تجربة تعليمية ثرية وجذابة للأطفال الذين تتراوح أعمارهم بين 2 و 5 سنوات"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" باستخدام منهج Oxford ، نقوم بالتدريس من خلال طريقة تعليم تعرف باسم 'Show & Tell 'لمساعدة التلاميذ في استكشاف محيطهم والتعرف على العالم من حولهم. هذا مدعوم بأربعة كتب لتنمية مهاراتهم الأكاديمية  : "])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كتاب الطالب "])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كتاب النشاط "])},
        "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كتاب الرياضيات "])},
        "item6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كتاب القراءة "])},
        "item7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" خارج الفصل الدراسي ، يمكن للتلاميذ التوجه إلى قاعة الألعاب المصممة لهذا الغرض،  والمجهزة بمنطقة لعب آمنة لهم لتطوير مهاراتهم الاجتماعية والحركية. هذه مجرد واحدة من العديد من المرافق والمزايا التي نقدمها والتي تعزز الاكتشاف المستقل، بتوجيه من معلمينا ذوي الخبرة و .الكفاءة"])},
        "item8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بشكل عام، ترتكز السنوات القليلة الأولى من التعلم في المدرسة الملكية على متعة التعليم. وهذا يمكن الأطفال من النمو بثقة ووعي بأنفسهم والآخرين وبيئتهم، ويوفر أساسًا متينًا لبقية .رحلتهم الأكاديمية معنا في مدرستنا الابتدائية"])}
      },
      "titlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدرستنا"])},
      "linkPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعمار التلاميذ"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحضانة ورياض الأطفال"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الابتدائية"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منهج STEAM"])},
        "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاندية والانشطة"])}
      },
      "otherLink": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواضيع ذات الصلة"])},
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإبتدائية"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسوم"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوظائف الشاغرة"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إتصل بنا"])}
      }
    },
    "clubsActivities": {
      "subtitlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاندية والانشطة"])},
      "linkList": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفحة الرئيسية"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدرستنا"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاندية والانشطة"])}
      },
      "contentPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إن تعليم طفلك ليس مجرد رحلة أكاديمية تتم في الفصل الدراسي فحسب، بل رحلة .اكتشاف واسعة تشجعه على اغتنام الفرص الجيدة و التعلم المستمر"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من خلال النوادي والأنشطة التي تزيد من مهارات التلاميذ عبر مجموعة واسعة من الفعاليات .التي تعزز لديهم التنمية الاجتماعية"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الاندية  :"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مع تركيز كل نادي على مجال معين من الاهتمام، يمكن للتلاميذ استكشاف الأنشطة الترفيهية التي تهمهم وتبادل الخبرات مع أقرانهم الذين لديهم نفس المواهب والاهتمامات. تشمل الأندية"])},
        "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدرسية ما يلي:-"])},
        "item6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نادي الكتاب "])},
        "item7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نادي الموسيقى "])},
        "item8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نادي الفنون "])},
        "item9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نادي الرياضة "])},
        "item10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نحن نتطلع دائمًا إلى تشكيل اندية جديدة اعتمادًا على الموضوعات التي يُظهر تلاميذنا حماسًا .فيها، لذا فإن هذه القائمة ليست شاملة بأي حال من الأحوال لكل الاندية التي نتطلع إليها"])},
        "item11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الأنشطة : "])},
        "item12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" مهمتنا كمعلمين هي تزويد التلاميذ بالخبرات التي تساعدهم على إيجاد طريقهم في الحياة. كجزء من التزامنا بهذا، نقوم بترتيب مجموعة متنوعة من الأنشطة التي تجمع فئات مجتمعنا معًا ."])},
        "item13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" يتم إجراء فئتين من الأنشطة في المدرسة الملكية :-"])},
        "item14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الانشطة المنهجية التي تتعلق بالمنهج الدراسي، والاحتفال المجالات الأكاديمية ذات الصلة - .(على سبيل المثال Pi Day ) حيث يشارك الأطفال في التعلم المركز حول الرياضيات"])},
        "item15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الانشطة العامة والوطنية - الأنشطة التي ترتبط بالأعياد والمناسبات الوطنية الحالية. هذه تدعم -.وعي التلاميذ بالثقافة والبلدان والتاريخ"])},
        "item16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل هذا يرتبط برؤيتنا المتمثلة في توفير تعليم جيد لكل طفل يمر عبر أبوابنا."])}
      },
      "titlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدرستنا"])},
      "linkPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعمار التلاميذ"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحضانة ورياض الأطفال"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الابتدائية"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منهج STEAM"])},
        "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاندية والانشطة"])}
      },
      "otherLink": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواضيع ذات الصلة"])},
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منهج STEAM"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الامن و السلامة"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدرستنا"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رؤيتنا"])}
      }
    }
  },
  "submitting": {
    "tourSchool": {
      "subtitlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جولة حول المدرسة"])},
      "linkList": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفحة الرئيسية"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقديم"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جولة حول المدرسة"])}
      },
      "contentPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يعد اختيار المدرسة أحد أكبر القرارات التي سيتخذها الآباء من أجل طفلهم , في المدرسة الملكية في بغداد نقدر مدى صعوبة ذلك. لدعمك في اختيارك ، ندعو جميع العائلات لزيارتنا في جولة ممتعة حول المدرسة ."])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد طريقة لفهم فوائد منهجنا التعليمي أفضل من القدوم لرؤية مدرستنا بنفسك. سيكون  من دواعي سرور موظف الاستقبال لدينا إرشادك حول الحضانة ورياض الأطفال والمدرسة  الابتدائية، حيث سيعرض لك مرافقنا الرائعة ويقدم لك شرحا وافيا عنها, سيكونون قادرين أيضًا على الإجابة على أي أسئلة لديك وتقديم مزيد من التفاصيل حول تصميم المدرسة والمناهج .وعملية القبول"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احجز جولة إما من خلال "])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" موقعنا الإلكتروني"])},
        "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أو عن طريق "])},
        "item6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأتصال بنا"])},
        "item7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ألق نظرة سريعة على مدرستنا في"])},
        "item8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" جولة حول المدرسة "])}
      },
      "titlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقديم"])},
      "linkPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجراءات القبول "])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسوم القبول"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جولة حول المدرسة"])}
      },
      "otherLink": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواضيع ذات الصلة"])},
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القبول"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدرستنا"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STEAM منهج "])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إتصل بنا"])}
      }
    },
    "registerFees": {
      "subtitlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسوم القبول"])},
      "linkList": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفحة الرئيسية"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقديم"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسوم القبول"])}
      },
      "contentPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الرسوم في المدرسة الملكية في بغداد شاملة لجميع الخدمات التعليمية والكتب الدراسية والزي المدرسي ما لم ينص على خلاف ذلك. الرسوم الدراسية يتم تسديدها على شكل أقساط .ميسرة على ثلاث دفعات"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الآباء الذين يسجلون أكثر من طفل واحد سيحصلون على خصم 20٪ لكل طفل إضافي يستمر  .كل عام يحضره أطفالهم"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لمزيد من المعلومات يمكنكم زيارة المدرسة او الاتصال بالأرقام التالية   :"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(9647733332656+ / 9647733332641+)"])}
      },
      "titlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقديم"])},
      "linkPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجراءات القبول "])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسوم القبول"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جولة حول المدرسة"])}
      },
      "otherLink": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواضيع ذات الصلة"])},
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القبول"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إتصل بنا"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جولة حول المدرسة "])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رؤيتنا"])}
      }
    },
    "admissionProcedures": {
      "subtitlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجراءات القبول"])},
      "linkList": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفحة الرئيسية"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقديم"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجراءات القبول"])}
      },
      "contentPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تهدف المدرسة الملكية إلى خلق جيل جديد فريد من نوعه ومستعد للتميز والإبداع .في عالمنا الحديث"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ولتحقيق هذا الهدف ، يشارك الأطفال الذين يتقدمون للدراسة معنا في عملية قبول دقيقة .تبيين مدى استيعابهم وقوة ملاحظتهم وطبيعة سلوكياتهم"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" متطلبات القبول :"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قبل التقديم إلى المدرسة الملكية ، نطلب أن يحصل جميع التلاميذ على اللقاحات المناسبة  لأعمارهم ويجب إثبات ذلك بشهادة صحية  ."])},
        "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" يحتاج جميع التلاميذ أيضًا إلى حضور مقابلة في المدرسة وإجراء الاختبارات ذات الصلة :- "])},
        "item6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" - إختبار أكاديمي :"])},
        "item7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتم إجراء الاختبارات الأكاديمية لجميع المتقدمين قبل قبولهم في المدرسة الملكية  ."])},
        "item8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تختلف الاختبارات المطلوبة حسب الفئة العمرية  : "])},
        "item9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" يتعين على الأطفال المتقدمين للالتحاق ب Kindergarten 1 اكمال اختبار التأشير فقط  ."])},
        "item10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تخضع جميع الأعمار الأخرى لاختبارات أكاديمية في اللغة الإنجليزية والعلوم والرياضيات ."])},
        "item11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- إختبار نفسي :"])},
        "item12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" نطلب أيضًا من جميع الأطفال إجراء اختبار نفسي يساعد في تشخيص أي حالات صحية عقلية أو احتياجات تعليمية إضافية، على سبيل المثال التوحد. فقط التلاميذ الذين يجتازون هذا الاختبار .سيكونون قادرين على التسجيل في المدرسة"])},
        "item13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- إستبيان :"])},
        "item14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" يطلب من أولياء الأمور ملء استبيان يخص حالة التلميذ الصحية و معلومات عن عدد أفراد الأسرة .وتسلسل الطفل في العائلة"])}
      },
      "titlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقديم"])},
      "linkPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجراءات القبول "])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسوم القبول"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جولة حول المدرسة"])}
      },
      "otherLink": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواضيع ذات الصلة"])},
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إتصل بنا"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسوم"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جولة حول المدرسة "])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التطوير"])}
      }
    }
  },
  "posts": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنشورات ذات الصلة"])}
  },
  "titleSection": {
    "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جولة حول المدرسة"])},
    "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أخبار المدرسة"])},
    "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المزيد من القصص"])},
    "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عائلتنا"])}
  },
  "home": {
    "TitleHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" إستثمر في مستقبل ابنائك"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اهلا وسهلا بكم في المدرسة الملكية حيث الاستثمار الأفضل في مستقبل اولادكم.، مدرسة فريدة من نوعها، تقدم تعليم عالي الجودة للتلاميذ الذين تتراوح أعمارهم من 2-12 سنة من  خلال خدمات تعليمية استثنائية. تهدف المدرسة الملكية في بغداد إلى تنمية القدرات الفكرية للأطفال على مدى 10 سنوات ، وإعدادهم للمرحلة التالية من تعليمهم."])},
    "content": {
      "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أهلا وسهلا"])},
      "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من المدير التنفيذي للمدرسة الملكية"])},
      "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حيدر غفوري"])}
    }
  },
  "slide1": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لماذا المدرسة الملكية؟"])},
    "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للتأكد من أننا ننشئ جيلًا قادرًا على التكيف مع عالمنا سريع التغير وإحداث التطور ، فإننا ندعم نهجنا التعليمي بمجموعة من خمس قيم أساسية: الشعور بالنزاهة المجتمعية ،'القيادة' ،'الحكمة' ، 'الاحترام' يعمل مدرسونا المحترفون المتحمسون على جعل الأطفال أفرادًا ليسوا خائفين من القيادة."])},
    "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رؤيتنا هي توفير تعليم جيد يمكّن الأطفال من اتباع مسارهم الخاص وإعدادهم للتغلب على تحديات الغد."])},
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيمنا"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رؤيتنا"])}
  },
  "slide2": {
    "name": {
      "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["علياء عبد الأمير"])},
      "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تهاني سعد"])}
    },
    "title": {
      "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المديرة"])},
      "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأستقبال"])}
    },
    "content": {
      "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" من الافراد الأوائل الذين كانوا ولازالوا سعيدين بالانضمام الى عائلة المدرسة الملكية، قبل سنين ابتدأنا برسم الخطوط العريضة لرؤية ورسالة المدرسة، مهمتي كمديرة هي السعي بكادرنا وتلاميذنا نحو التميز والإبداع."])},
      "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" مهاراتي المتعددة كموظفة استقبال في المدرسة هي التواصل الفعال مع أولياء الأمور والضيوف، والقدرة التنظيمية العالية، هي من أهم مايميزني في المدرسة الملكية. "])}
    }
  },
  "library": {
    "nursery": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحضانة"])},
      "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من 2 إلى 4 سنوات"])}
    },
    "kindergarten": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رياض الأطفال"])},
      "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من 4 إلى 6 سنوات"])}
    },
    "primary": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإبتدائية"])},
      "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من 6 إلى 12 سنة"])},
      "classes": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصف الاول "])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصف الثاني"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصف الثالث"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصف الرابع"])},
        "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصف الخامس"])},
        "item6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصف السادس"])}
      }
    },
    "steam": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منهج STEAM "])}
    },
    "teachers": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلمات والتلاميذ"])}
    },
    "parents": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رأي أولياء الأمور"])}
    }
  },
  "form": {
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بمن ترغب بالاتصال؟"])},
    "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان البريد الإلكتروني"])},
    "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهاتف"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
    "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السيد"])},
    "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السيدة"])},
    "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأنسة"])},
    "item6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدكتور"])},
    "item7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البروفسور"])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ألاسم الاول"])},
    "title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ألاسم الثاني"])},
    "title5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أسم العائلة"])},
    "title6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان البريد الألكتروني"])},
    "title7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهاتف"])},
    "title8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان السكن"])},
    "title9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسالة"])}
  },
  "jobRequest": {
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأسم الكامل"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة الأجتماعية"])},
    "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متزوج"])},
    "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعزب"])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العمر"])},
    "title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوظيفة المطلوبة"])},
    "title5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحصيل الدراسي"])},
    "title6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهاتف"])},
    "title7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان البريد الألكتروني"])},
    "title8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البلد"])},
    "title9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان السكن"])}
  }
}