<template>
    <div>
        <section class="Pages">
            <header class="pageHeader">
                <div class="sub-TitlePage">{{ $t('ourSchool.pupilsAges.subtitlePage') }}</div>
                <ul class="linkList d-flex flex-row">
                    <li>
                        <router-link  :to="`/`">{{ $t('ourSchool.pupilsAges.linkList.item1') }}</router-link>
                    </li>
                    <li class="line"></li>
                    <li>
                        <router-link to="">{{ $t('ourSchool.pupilsAges.linkList.item2') }}</router-link>
                    </li>
                    <li class="line"></li>
                    <li>
                        <router-link to="">{{ $t('ourSchool.pupilsAges.linkList.item3') }}</router-link>
                    </li>
                </ul>
                <div class="overlay"></div>
                <img class="imgHeader" src="image/image-from-rawpixel-id-99470-jpeg.webp" alt="" lazy="loading"/>
                <img class="shareIcon" src="image/share.svg" width="100%" @click="hoverShare"/>
                <div class="shareList" :class="{'slide':slide}">
                 <ul>
                <li><a href="http://twitter.com/share?url=https://www.theroyalschool.com/PupilsAges"  class="twitter">Twitter</a></li>
                <li> <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.theroyalschool.com/PupilsAges" target="_blank" class="facebook">Facebook</a></li>
                <li> <a href="mailto:?subject=I wanted you to see this site&amp;body=Check out this site https://www.theroyalschool.com/PupilsAges" class="email">Email Link</a></li>
                </ul>
                </div>
            </header>

            <div class="contentPage">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" md="9">
                            <ul class="d-flex flex-column align-start">
                                <li class="titleContent">
                                 {{ $t('ourSchool.pupilsAges.contentPage.item1') }}
                                </li>

                                <li class="textContent">
                                 {{ $t('ourSchool.pupilsAges.contentPage.item2') }}
                                </li>
                                <ul>
                                    <li class="titleContent">
                                       {{ $t('ourSchool.pupilsAges.contentPage.item3') }}
                                    </li>
                                    <li class="textContent">
                                    {{ $t('ourSchool.pupilsAges.contentPage.item4') }}
                                    </li>
                                </ul>

                                <ul>
                                    <li class="titleContent">
                                       {{ $t('ourSchool.pupilsAges.contentPage.item5') }}
                                    </li>
                                    <li class="textContent">
                                     {{ $t('ourSchool.pupilsAges.contentPage.item6') }}
                                    </li>
                                </ul>

                                <ul>
                                    <li class="titleContent">
                                      {{ $t('ourSchool.pupilsAges.contentPage.item7') }}
                                    </li>
                                    <li class="textContent">
                                       {{ $t('ourSchool.pupilsAges.contentPage.item8') }}
                                    </li>
                                </ul>
                                <li class="textContent">
                                   {{ $t('ourSchool.pupilsAges.contentPage.item9') }}
                                </li>
                                <li class="titleContent">
                                    {{ $t('ourSchool.pupilsAges.contentPage.item10') }}
                                </li>
                                <li class="textContent">
                                   {{ $t('ourSchool.pupilsAges.contentPage.item11') }}
                                </li>
                                <li class="textContent">
                                  {{ $t('ourSchool.pupilsAges.contentPage.item12') }}
                                </li>
                                <li class="textContent">
                                  {{ $t('ourSchool.pupilsAges.contentPage.item13') }}
                                </li>
                                <li class="textContent">
                                   {{ $t('ourSchool.pupilsAges.contentPage.item14') }}
                                </li>

                                <li class="textContent">
                                   {{ $t('ourSchool.pupilsAges.contentPage.item15') }}
                                </li>
                            </ul>
                        </v-col>
                        <v-col cols="12" md="3" class="d-flex flex-column justify-end">
                            <div class="titlePage">
                                <ul class="d-flex flex-column align-end">
                                    <li>
                                        <router-link to="">{{ $t('ourSchool.pupilsAges.titlePage') }}</router-link>
                                    </li>
                                </ul>
                            </div>
                            <div class="navPage">
                                <ul class="d-flex flex-column align-end">
                                    <li class="linkPage activeLink">
                                        <router-link  :to="`/`">{{ $t('ourSchool.pupilsAges.linkPage.item1') }}</router-link>
                                    </li>
                                    <li class="linkPage">
                                        <router-link  :to="`/NurseryKindergarten`">{{ $t('ourSchool.pupilsAges.linkPage.item2') }}</router-link>
                                    </li>
                                    <li class="linkPage">
                                        <router-link  :to="`/Primary`">{{ $t('ourSchool.pupilsAges.linkPage.item3') }}</router-link>
                                    </li>
                                    <li class="linkPage">
                                        <router-link  :to="`/Steam`">{{ $t('ourSchool.pupilsAges.linkPage.item4') }} </router-link>
                                    </li>
                                    <li class="linkPage">
                                        <router-link  :to="`/ClubsActivities`">{{ $t('ourSchool.pupilsAges.linkPage.item5') }}</router-link>
                                    </li>
                                </ul>
                            </div>
                            <div class="otherLink">
                                <p>{{ $t('ourSchool.pupilsAges.otherLink.title') }}</p>
                                <ul class="d-flex flex-column align-end">
                                    <li>
                                        <ul class="d-flex flex-row">
                                            <li>
                                                <router-link :to="`/NurseryKindergarten`">
                                                    <p>{{ $t('ourSchool.pupilsAges.otherLink.item1') }}</p>
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link :to="`/NurseryKindergarten`">
                                                    <div class="img size"><img src="image/abc.svg" /></div>
                                                </router-link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul class="d-flex flex-row">
                                            <li>
                                                <router-link :to="`/Primary`">
                                                    <p>{{ $t('ourSchool.pupilsAges.otherLink.item2') }}</p>
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link :to="`/Primary`">
                                                    <div class="img size"><img src="image/book-open-solid.svg" /></div>
                                                </router-link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul class="d-flex flex-row">
                                            <li>
                                                <router-link :to="`/RegisterFees`">
                                                    <p>{{ $t('ourSchool.pupilsAges.otherLink.item3') }}</p>
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link :to="`/RegisterFees`">
                                                    <div class="img size"><img src="image/payment.svg" /></div>
                                                </router-link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul class="d-flex flex-row">
                                            <li>
                                                <router-link :to="`/TourSchool`">
                                                    <p>{{ $t('ourSchool.pupilsAges.otherLink.item4') }}</p>
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link :to="`/TourSchool`">
                                                    <div class="img size"><img src="image/bus.svg"/></div>
                                                </router-link>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </section>
    </div>

</template>
<script>
  export default {
    data() {
      return {
          slide:null
      }
    },
    methods:{
        hoverShare() {
          this.slide = !this.slide; 
        }
    },
  }
</script>
