<template>
    <div>
        <header class="header">
            <div class="contentSlide">
                <div class="title wow fadeInUp" data-wow-duration='3s' data-wow-delay='1s'>
                    <ul class="d-flex flex-column align-center">
                        <li>{{ $t('home.TitleHeader') }}</li>
                        <li>#ROYALSCHBAGHDAD</li>
                    </ul>
                </div>
                <!-- 
                <div class="itemSlide" v-for="(slide ,index) in slides" :key="slide.id"
                    :class="{ 'activeSlides': index === 0}">
                    <div class="contentItem">
                        <div class="overlay"></div>
                        <video autoplay="true" loop="" muted="" playsinline="">
                            <source :src="slide" type="video/mp4">
                        </video>

                    </div>
                </div>
                -->
<div class="itemSlide activeSlides">
                    <div class="contentItem">
                        <div class="overlay"></div>
                        <video autoplay="true" loop="" muted="" playsinline="">
                            <source src="image/3190286567.mp4" type="video/mp4">
                        </video>

                    </div>
                </div>

            </div>
            <div class="marquee">
                    <button class="ticker__icon"><img src="image/Attention.svg"/></button>
                    <div class="span d-flex flex-row">
                    <ul class="scroll_news ticker__content">
                            <li v-for="data in dataNews" :key="data.id">
                            <a href=""><img src="image/logo.webp" style="width:30px;margin-bottom: -10px;"/> {{data.name}}</a>
                            </li>
                    </ul>
                      </div>

            </div>
        </header>

    </div>
</template>
<script>

// import axios from 'axios';
    export default {
        data(){
            return{
            dataNews:[],
            // slides: [
            //     "https://player.vimeo.com/progressive_redirect/playback/690905515/rendition/1080p?loc=external&signature=906f95f034f3b11a49d7abba30329d96392a644a0f048c53d16c8a4d7bc7ee0a",
            // ],
            }
        },
         mounted() {
            fetch('https://faya.dev/apiProjections/public/api/posts?storeId=3&type=hotNews')
            .then(res => res.json())
            .then(data => this.dataNews=data.data)
         }
    }
</script>
