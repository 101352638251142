<template>
    <div>
        <div class="seactionTwo">
            <v-container>
                <v-row>
                    <v-col cols="12" md="6" sm="6" class="
            d-flex justify-start
            align-center
            items-center
            ">
                        <div class="seaction-content" id="seactionContent">
                            <ul class="d-flex flex-column align-start">
                                <li>
                                    <p>{{ $t('home.content.item1') }}</p>
                                </li>
                                <li>{{ $t('home.content.item2') }}</li>
                                <li>{{ $t('home.content.item3') }}</li>
                                <li>
                                    <div class="lineSection"></div>
                                </li>
                                <li class="text-right">
                                    {{ $t('home.text') }}
                                </li>
                            </ul>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6" sm="12" class="
            d-flex justify-center
            align-center
            items-center
            ">
      

                    </v-col>

                </v-row>
            </v-container>
        </div>

        <v-container fluid>
            <v-row>
                <v-col cols="12">
                    <Slide2 />
                </v-col>
            </v-row>
        </v-container>

        <div class="seactionOne">
            <v-container>
                <v-row>
                    <v-col md="12" cols="0" justify="center" class="text-center">
                        <div class="seaction-title">
                            {{ $t('titleSection.item1') }}
                        </div>
                    </v-col>
                    <v-col md="12" cols="12" justify="center" class="justify-center d-flex text-center">
                        <div class="videoSection">
                        <iframe
                                src="https://player.vimeo.com/video/824562161?h=ec964b416e&title=0&byline=0&portrait=0"
                                frameborder="0" allow="autoplay; fullscreen; picture-in-picture"
                                allowfullscreen></iframe>
                            <iframe
                                src="https://player.vimeo.com/video/824564292?h=12617a1871&title=0&byline=0&portrait=0"
                                frameborder="0" allow="autoplay; fullscreen; picture-in-picture"
                                allowfullscreen></iframe>
                            
                        </div>
                    </v-col>

                </v-row>
            </v-container>
        </div>

        <div class="seactionFour" id="seactionFour">
            <v-container justify="center">
                <v-row justify="center">
                    <v-col md="12" justify="center" class="text-center">
                        <div class="titleSection">{{ $t('titleSection.item2') }}</div>
                        <Cards />
                        <br><br>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <router-link to="/AllPosts" class="btn-more">{{ $t('titleSection.item3') }}</router-link>
                </v-row>
            </v-container>
        </div>

        <v-container fluid>
            <v-row>
                <v-col cols="12">
                    <Slide3 />
                </v-col>
            </v-row>
        </v-container>

        <div class="seactionFive" id="seactionFive">
            <v-container fluid>
                <v-row justify="center">
                    <v-col md="12" cols="12" justify="center" class="text-center">
                        <div class="seaction-title" dir="ltr">
                            {{ $t('whereNext.title') }}
                        </div>
                    </v-col>
                    <v-col md="12">
                        <ul class="d-flex flex-row justify-center flex-wrap">
                            <li class="btn-nextDestination">
                                <router-link to="/ClubsActivities">
                                    <ul class="d-flex flex-column content-center align-center">
                                        <li>
                                            <img src="image/trophy.svg" alt="" />
                                        </li>

                                        <li>
                                            <p>{{ $t('whereNext.clubsActivities') }}</p>
                                        </li>
                                    </ul>
                                </router-link>
                            </li>
                            <li class="btn-nextDestination">
                                <router-link to="/Primary">
                                    <ul class="d-flex flex-column content-center align-center">
                                        <li>
                                            <img src="image/booksolid.svg" alt="" />
                                        </li>
                                        <li>
                                            <p>{{ $t('whereNext.elementary') }}</p>
                                        </li>
                                    </ul>
                                </router-link>
                            </li>
                            <li class="btn-nextDestination">
                                <router-link to="">
                                    <ul class="d-flex flex-column content-center align-center">
                                        <li>
                                            <span>{{ count }}</span>
                                        </li>
                                        <li>
                                            <p>{{ $t('whereNext.visitor') }}</p>
                                        </li>
                                    </ul>
                                </router-link>
                            </li>
                            <li class="btn-nextDestination">
                                <router-link to="/ContactUs">
                                    <ul class="d-flex flex-column content-center align-center">
                                        <li>
                                            <img src="image/chat.svg" alt="" />
                                        </li>
                                        <li>
                                            <p>{{ $t('whereNext.contactUs') }}</p>
                                        </li>
                                    </ul>
                                </router-link>
                            </li>
                            <li class="btn-nextDestination ">
                                <router-link to="/ParentsSociety">
                                    <ul class="d-flex flex-column content-center align-center">
                                        <li>
                                            <img src="image/people-svgrepo-com.svg" alt="" />
                                        </li>
                                        <li>
                                            <p>{{ $t('whereNext.parentsCommunity') }}</p>
                                        </li>
                                    </ul>
                                </router-link>
                            </li>

                        </ul>
                    </v-col>
                </v-row>
            </v-container>
        </div>

    </div>
</template>

<script>
import Cards from "@/components/cards/Cards.vue";
import Slide2 from "@/components/slides/Slide2.vue";
import Slide3 from "@/components/slides/Slide3.vue";
import axios from 'axios';

export default {
    data() {
        return {
            count: null,
        }
    },
    components: {
        Cards,
        Slide2,
        Slide3
    },
    mounted() {
        axios
            .get('https://faya.dev/apiProjections/public/api/stores/3/count')
            .then(response => (this.count = response.data))
            .catch(error => console.log(error))
    }
}
</script>
