<template>
<div>
<div class="bgWeb"></div>
<div class="bgWeb_Light"></div>
  <div class="homeAr">
 
   <HeaderPro></HeaderPro>
   <ContentHome></ContentHome>
    </div>
</div>
</template>
<script>
import HeaderPro from "@/components/Header.vue";
import ContentHome from "@/components/ContentHome.vue";

export default {
  components: {
    HeaderPro,
   ContentHome
  },
};
</script>

<style type="text">
.bg{
  height: 2000px;
}

</style>