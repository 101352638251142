export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en"])},
  "nav": {
    "faContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact"])},
    "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gallery"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
    "dropContact": {
      "visit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VISIT"])},
      "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APPLY"])},
      "enquiy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENQUIRY"])}
    },
    "Menu": {
      "menuName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MENU"])},
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME"])},
      "aboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABOUT US"])},
      "schooLife": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCHOOL LIFE"])},
      "admissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADMISSIONS"])},
      "ourCommunity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OUR COMMUNITY"])},
      "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTACT US"])},
      "visit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VISIT"])},
      "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APPLY"])},
      "enquiy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENQUIRY"])}
    },
    "subMenu": {
      "aboutUs": {
        "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WELCOME"])},
        "ourVision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OUR VISION"])},
        "campusFacilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAMPUS & FACILITIES"])},
        "securityWellbeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECURITY & WELLBEING"])}
      },
      "schoolLife": {
        "pupilsAges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OUR SCHOOL (AGES 2-4)"])},
        "kinderGarten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KINDERGARTEN (AGES 4-6)"])},
        "elementary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ELEMENTARY (AGES 6-12)"])},
        "steam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STEAM EDUCATION"])},
        "clubsActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CLUBS & ACTIVITIES"])}
      },
      "admissions": {
        "admissionsProcess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADMISSIONS PROCESS"])},
        "admissionsFees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADMISSION FEES"])},
        "schoolTours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCHOOL TOURS"])}
      },
      "ourCommunity": {
        "parentsCommunity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PARENTS & COMMUNITY"])},
        "development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEVELOPMENT"])},
        "jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOB VACANCIES"])}
      }
    }
  },
  "footer": {
    "visit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VISIT"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APPLY"])},
    "enquiy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENQUIRY"])},
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow On"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baghdad - Iraq / Zayouna - behind Zayouna Mall"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms"])},
    "sitemap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitemap"])}
  },
  "whereNext": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHERE NEXT ?"])},
    "parentsCommunity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parents"])},
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ContactUs"])},
    "visitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["visitors"])},
    "elementary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["elementary"])},
    "clubsActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clubsActivities"])}
  },
  "contactUs": {
    "sectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "titleContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Data"])},
    "info": {
      "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail "])},
      "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here for more information"])},
      "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the address :"])},
      "item6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baghdad - Zayouna - behind Zayouna Mall"])},
      "item7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Head office phone"])}
    }
  },
  "abouteUs": {
    "welcomeMessage": {
      "subtitlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WELCOME TO THE ROYAL SCHOOL"])},
      "linkList": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABOUT US"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WELCOME"])}
      },
      "contentPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invest in your child's future with us!"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A unique school, providing high-quality education to pupils aged 2-12 years through exceptional educational services. The Royal School in Baghdad aims to develop the intellectual abilities of children over a period of 10 years, preparing them for the next stage of their education."])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A ten-year journey with us, which provides students with the opportunity to learn distinguished curricula at the global level, that will develop the student's skills. An example of this is a curriculum that creates a realistic experience in which lessons (science, technology, engineering, art, and mathematics) are integrated. Based on the international language of communication (English), where students learn the skill of dialogue with different cultures of the world, supporting the psychological aspect of students to ensure their enjoyment and happiness in our study journey."])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to provide a high and advanced level of education, advanced and advanced educational facilities and facilities are required, which is something we are proud to offer at the Royal School in Baghdad. Our comfortable, safe and well-equipped environment allows students to focus on their studies and achieve their potentials."])},
        "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pupils are encouraged to excel within a broad curriculum, supported by highly qualified teachers. They fulfill their potential in a comfortable study environment filled with facilities of the highest international standards. English is used on a daily basis in the Royal School - it is not taught for educational purposes. only but to expand the life opportunities that our students can"])},
        "item6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected candidates will be required to take mental and skill tests, participate in an interview (either online or in person) and - if successful - the candidate will be asked to submit a full field lesson before the panel"])},
        "item7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our mission is to prepare students for a prosperous future. We strive to provide a great foundation for secondary education and are committed to inspiring the next generation to work in a team spirit. The student's use of his knowledge makes him able to make development. Each student is able to achieve his full potential through our specialized support, The pupils will graduate from the Royal School as individuals who are confident and ready for a confrontation."])}
      },
      "titlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["about us"])},
      "linkPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Vision"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campus & Facilities"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security & Wellbeing"])}
      },
      "otherLink": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHERE NEXT ?"])},
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admissions"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kindergarten"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elementary"])}
      }
    },
    "safetySecurity": {
      "subtitlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECURITY & WELLBEING"])},
      "linkList": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABOUT US"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security & Wellbeing "])}
      },
      "contentPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The safety of our students is of paramount importance to us at the Royal School, and this is reflected in a number of security and prevention measures."])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To ensure that every student and staff member stays safely in the school, there is a surveillance camera system in place which provides full coverage throughout the school, with high definition filming. Vehicle license plates are recorded to ensure that students leave at the end of the day with the concerned family members, and are also recorded Data. Persons coming to pick up the students to ensure that the children arrive with trusted people."])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The school provides security guards 24/7, ready to deal with any accident in order to maintain a safe environment for the school."])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our behavior policies ensure that our school provides a welcoming atmosphere for all. Bullying and discrimination are not tolerated. Abuse by anyone at the school is not tolerated"])},
        "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our conduct policies ensure that our school provides a welcoming atmosphere for all. Bullying and discrimination are not tolerated. Abuse by anyone at the school is not tolerated"])},
        "item6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To ensure we are able to meet each child’s individual needs at meal times regardless of dietary requirements, cards will be issued to parents providing information on allergies and recommendations for KG and KG children. Cards will be handed over to catering staff to ensure students have appropriate food options."])},
        "item7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the current circumstances, we are committed to implementing measures to prevent Covid-19 by providing hand sanitizers, using disinfection systems, imposing the wearing of masks and social distancing. In addition, all staff working in the school are committed to taking the Covid-19 vaccine, and we also hope that all Students, staff and visitors adhere to these guidelines for the safety of all."])},
        "item8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In addition to that, the school is equipped with an integrated medical unit specially equipped for the school with a doctor and assistant ready throughout official working hours and ready for any situation. In addition to conducting a periodic examination for students to ensure their health and providing each student with a periodic report on his health status."])}
      },
      "titlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
      "linkPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Vision"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campus & Facilities"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security & Wellbeing"])}
      },
      "otherLink": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHERE NEXT ?"])},
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Vacancies"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Life "])}
      }
    },
    "OurVision": {
      "subtitlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OUR VISION"])},
      "linkList": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABOUT US"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OUR VISION"])}
      },
      "contentPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Royal School was created by a team of experts from all over the world, who identified the gap that exists in the field of education and came together to fill it."])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our enthusiastic educational professionals combine their knowledge of innovative tools to deliver a bright future for the next generation, striving to realize our vision in"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vision :"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our vision is to provide quality education that enables students to follow their own path and prepares them to overcome the challenges of tomorrow. Our motto 'Invest in your children's future' is an acronym that sums up the qualities of the school"])},
        "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["values ​​:"])},
        "item6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To ensure the upbringing of a generation capable of adapting to a rapidly changing world and bringing about development in it, we support our educational approach with five values: "])},
        "item7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a sense of belonging to the community"])},
        "item8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrity"])},
        "item9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leadership"])},
        "item10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wisdom"])},
        "item11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["respect"])},
        "item12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With these enabling principles, our graduates will not fear change, confidently continue to innovate, and lead the community with success and honesty."])}
      },
      "titlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
      "linkPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Vision"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campus & Facilities"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security & Wellbeing"])}
      },
      "otherLink": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHERE NEXT ?"])},
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kindergarten"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elementary"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admissions"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development"])}
      }
    },
    "buildSchool": {
      "subtitlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAMPUS & FACILITIES"])},
      "linkList": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABOUT US"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAMPUS & FACILITIES"])}
      },
      "contentPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The school's building is designed in a way that takes into account the maximum degree of comfort, luxury and safety for students, and in all respects."])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For example, large glass windows and doors provide ample natural light to aid focus and academic performance, using high-strength safety glass that filters UV rays and is insulated to maintain comfortable temperatures. Manufactured by a dedicated team from Alumni"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional lighting has been designed and installed by company experts in accordance with the highest standards."])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classrooms, halls and all school facilities were provided with air conditioning systems to maintain a comfortable environment in all seasons of the year."])},
        "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flooring is designed and prepared by GerFlor to be anti-bacterial, anti-slip, anti-slip, shockproof and soundproof"])},
        "item6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outdoor walkways and courtyards covered with artificial grass that is fire-resistant, anti-bacterial, anti-allergic, environmentally friendly and free of toxicity"])},
        "item7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The school is equipped with a comprehensive fire alarm and extinguishing system that covers all school facilities, developed by a company."])},
        "item8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audio and video surveillance cameras have been installed and installed throughout the school, which work around the clock, seven days a week, using high-tech camera technology."])},
        "item9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In addition, we have flexible learning spaces such as the large hall ideal for a range of uses including indoor sports, plays, events, events and other occasions in addition to other indoor games hall to meet all the needs of students."])},
        "item10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The school is equipped with an integrated medical unit specially equipped for the school with a doctor and assistant ready throughout official working hours, ready for any situation. In addition to that, a periodic examination of students is conducted to ensure their health safety."])},
        "item11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are also proud to be the first educational institution in Iraq to provide air purifiers from Swedish, providing more Blueair air purification in the spaces occupied by our young children by removing dust, pollen, bacteria and viruses from the air."])},
        "item12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to ensure that our facilities remain distinguished and safe in compliance with international standards, the Royal School has cooperated with a group of companies of the best providers of educational and allied services around the world, including the leading school design company, which designed the school in a way that allows for good ventilation Changing the air and allowing students to have plenty of COVID19 space helps prevent the spread of diseases, including"])},
        "item13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The large and modern cafeteria ensures that students are provided with healthy nutrition for their day and provide comfortable spaces to spend their break times."])},
        "item14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Multiple spacious car parks allow students to be collected and delivered without any pressure and in a safe manner."])},
        "item15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technology plays an essential role in our work, with classrooms equipped with smart screens and applications for education relevant to our times."])},
        "item16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Using the global administrative system, which creates a special link between parents, staff, students, and all aspects of the educational process at the same time."])},
        "item17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All these carefully considered features combine to create a modern setting, the Royal School in Baghdad. We hope to meet you and take you on a tour of the magnificent school building soon."])}
      },
      "titlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
      "linkPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Vision"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campus & Facilities"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security & Wellbeing"])}
      },
      "otherLink": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHERE NEXT ?"])},
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our School"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admissions"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STEAM"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clubs & ActivitiesClubs"])}
      }
    }
  },
  "family": {
    "developer": {
      "subtitlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEVELOPMENT"])},
      "linkList": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OUR COMMUNITY"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEVELOPMENT"])}
      },
      "contentPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuous development is something we dedicate ourselves to at the Royal School."])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["includes the development of students' personal skills and the continuous improvement of our school. We strive to ensure that each child under our care achieves the best of his or her activities and abilities through the use of effective tools, methods and teaching methods."])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All school events and activities are regularly reviewed to ensure that we continue to make an effective impact on learning while keeping learning fun and engaging."])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The educational consultant is a key member of our team that monitors students' behavior and daily activities. Periodic reports on students' behavior are issued to parents and specialized strategies are developed to develop students' personality and social skills."])}
      },
      "titlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Community"])},
      "linkPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parents & Community"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Vacancies"])}
      },
      "otherLink": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHERE NEXT ?"])},
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parents"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Vision"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campus & Facilities"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security & Wellbeing"])}
      }
    },
    "jobs": {
      "subtitlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOB VACANCIES"])},
      "linkList": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OUR COMMUNITY"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOB VACANCIES"])}
      },
      "contentPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you a professional looking to make a difference in the education of the next generation? If so, we would be happy to contact you."])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The vision of the Royal School is to provide a quality education that will enable children to follow their own path and prepare them to overcome the challenges of tomorrow. This is something we cannot achieve without a team of dedicated staff."])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply to work with us!"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicants living in Iraq must simply submit their CV to the Department in person"])},
        "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicants living outside Iraq must submit their CV online, and their interview will be online as well."])},
        "item6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected candidates will be required to take mental and skill tests, participate in an interview (either online or in person) and - if they are successful - the candidate will be asked to present a full field lesson before the relevant committee as proof of their teaching abilities"])},
        "item7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are interested in working with us, we are glad to hear from you."])}
      },
      "titlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Community"])},
      "linkPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parents & Community"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Vacancies"])}
      },
      "otherLink": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHERE NEXT ?"])},
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our School"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Vision"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security & Wellbeing"])}
      }
    },
    "parentsSociety": {
      "subtitlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PARENTS & COMMUNITY"])},
      "linkList": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OUR COMMUNITY"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PARENTS & COMMUNITY"])}
      },
      "contentPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parents' involvement is greatly appreciated at the Royal School of Baghdad - we encourage parents to participate in their children's educational journey and participate in our community."])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To support this, we have created a platform for parents to keep them aware of their children’s activities and events. Through .meetings between parents and teachers and home visits by relevant staff"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have an extensive program to enable communication with parents throughout the time their children spend with us, using the latest advanced technological programs, which record and follow up the behavior and all daily activities of students, and share them with parents through smart devices and their applications"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This helps in identifying any problems at an early stage, which benefits all stakeholders and achieves our vision of providing quality and advanced education in a way that contributes to building and developing children's personality correctly."])}
      },
      "titlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Community"])},
      "linkPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parents & Community"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Vacancies"])}
      },
      "otherLink": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHERE NEXT ?"])},
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clubs & Activities"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Vacancies"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Vision"])}
      }
    }
  },
  "ourSchool": {
    "steam": {
      "subtitlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STEAM EDUCATION"])},
      "linkList": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCHOOL LIFE"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STEAM EDUCATION"])}
      },
      "contentPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The STEAM curriculum is a dynamic curriculum that combines science, technology, engineering, arts, and mathematics - enabling children from a young age to thrive in today's rapidly changing world"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Royal School teaches the STEAM curriculum using Skriware technology to provide a practical approach to learning that is implemented using modern teaching tools and materials."])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The STEAM curriculum enables students to acquire knowledge and skills and demonstrate their abilities in a scientific and practical way. This curriculum not only supports academic achievement but also enhances relationships between students and as a result develops his social skills."])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["During the classroom, children use specialized software and modern technology to participate in a range of activities from 3D printing to robotics and programming. Through these activities they become innovative with a thirst for knowledge, enthusiasm and a passion for learning that they will carry with them throughout life."])}
      },
      "titlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Life"])},
      "linkPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our School (Ages 2-4)"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kindergarten (Ages 4-6)"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elementary (Ages 6-12)"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STEAM Education"])},
        "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clubs & Activities"])}
      },
      "otherLink": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHERE NEXT ?"])},
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elementary"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kindergarten"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our School"])}
      }
    },
    "pupilsAges": {
      "subtitlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OUR SCHOOL (AGES 2-12)"])},
      "linkList": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCHOOL LIFE"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OUR SCHOOL (AGES 2-12)"])}
      },
      "contentPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stages:"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Royal School is divided into three main stages: "])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nursery:"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From 2 to 4 years old"])},
        "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kindergarten:"])},
        "item6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from 4 to 6 years old"])},
        "item7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["elementary:"])},
        "item8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from 6 to 12 years old"])},
        "item9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each of these age groups is led by a dedicated and specialized teaching staff that teaches at a level appropriate to the specific age group, and in a manner that enhances students' abilities at every step of their development."])},
        "item10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curriculum:"])},
        "item11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We offer a unique type of curriculum that is not found anywhere else, as we combine the government curriculum, the Australian curriculum, and the Oxford curriculum, which are used in a scientific and thoughtful manner in different fields and topics to provide a unique and effective learning experience."])},
        "item12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The government curriculum forms the basis of our approach to teaching."])},
        "item13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We follow the Oxford curriculum in science, mathematics and computer studies."])},
        "item14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STEAM is used to direct enrichment activities in science, technology, engineering, arts, and mathematics."])},
        "item15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The English language is used as a basic language in teaching as it is the first global language of communication and dialogue, in addition to focusing on the Arabic language in the subjects of Islamic education and Arabic."])}
      },
      "titlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Life"])},
      "linkPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our School (Ages 2-4)"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kindergarten (Ages 4-6)"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elementary (Ages 6-12)"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STEAM Education"])},
        "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clubs & Activities"])}
      },
      "otherLink": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHERE NEXT ?"])},
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kindergarten"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elementary"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fees"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Tours"])}
      }
    },
    "primary": {
      "subtitlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ELEMENTARY (AGES 5-12)"])},
      "linkList": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCHOOL LIFE"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ELEMENTARY (AGES 5-12)"])}
      },
      "contentPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["During the six years of primary school that the students spend with us, we enhance the skills and academic achievements while continuing the students’ basic personal skills"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the primary years, we follow the government curriculum and carefully selected enrichment curricula to provide high quality and comprehensive education."])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["curricula are taught side by side with the governmental curricula with the governmental curricula"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" The STEAM and Oxford curriculum for Mathematics, Science and Computer is taught from first to sixth grade."])},
        "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STEAM (Science, Technology, Engineering, Art, Mathematics) is taught from Kindergarten KG2 to Grade 6."])}
      },
      "titlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Life"])},
      "linkPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our School (Ages 2-4)"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kindergarten (Ages 4-6)"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elementary (Ages 6-12)"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STEAM Education"])},
        "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clubs & Activities"])}
      },
      "otherLink": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHERE NEXT ?"])},
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kindergarten"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our School"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Vacancies"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])}
      }
    },
    "nurseryKindergarten": {
      "subtitlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KINDERGARTEN (AGES 2-5)"])},
      "linkList": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCHOOL LIFE"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KINDERGARTEN (AGES 2-5)"])}
      },
      "contentPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Nursery and Kindergarten programs provide a rich and engaging learning experience for children between the ages of 2 and 5 years"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Using the Oxford curriculum, we teach through a teaching method known as 'Show & Tell' to help pupils explore their surroundings and learn about the world around them. This is supported by four books to develop their academic skills: "])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student's Book"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Book"])},
        "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Math Book"])},
        "item6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Reading Book"])},
        "item7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outside the classroom, pupils can head into a purpose-built playroom, which is equipped with a safe play area for them to develop their social and motor skills. This is just one of the many facilities and features we offer that promote independent discovery, with the guidance of our experienced teachers and efficiency"])},
        "item8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overall, the first few years of learning at Royal School are centered on the joy of education. This enables children to grow with confidence and awareness of themselves, others and their environment, and provides a solid foundation for the rest of their academic journey with us at our primary school."])}
      },
      "titlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Life"])},
      "linkPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our School (Ages 2-4)"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kindergarten (Ages 2-4)"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elementary (Ages 6-12)"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STEAM Education"])},
        "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clubs & Activities"])}
      },
      "otherLink": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHERE NEXT ?"])},
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elementary"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fees"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Vacancies"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])}
      }
    },
    "clubsActivities": {
      "subtitlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CLUBS & ACTIVITIES"])},
      "linkList": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCHOOL LIFE"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CLUBS & ACTIVITIES"])}
      },
      "contentPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educating your child is not just an academic journey that takes place in the classroom, but rather a broad journey of discovery that encourages him to seize good opportunities and continuous learning."])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Through clubs and activities that increase students' skills through a wide range of activities, which enhance their social development"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Clubs:"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With each club focusing on a specific area of ​​interest, students can explore recreational activities that interest them and share experiences with peers who have the same talents and interests. Clubs include"])},
        "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School includes:-"])},
        "item6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book Club"])},
        "item7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Music Club"])},
        "item8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arts Club"])},
        "item9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sports Club"])},
        "item10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are always looking to form new clubs based on the topics that our students are passionate about. So this list is by no means exhaustive for all the clubs we are looking at."])},
        "item11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities:"])},
        "item12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our mission as educators is to provide students with experiences that help them find their own path in life. As part of our commitment to this, we arrange a variety of activities that bring our community groups together."])},
        "item13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Two classes of activities are conducted in the Royal School :-"])},
        "item14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curricular activities related to the curriculum, celebrating related academic fields - (eg Pi Day) where children participate in focused learning around mathematics."])},
        "item15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General and patriotic activities - activities that are related to current national holidays and occasions. These support -. students' awareness of culture, countries and history."])},
        "item16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It all relates to our vision of providing quality education to every child who passes through our doors."])}
      },
      "titlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Life"])},
      "linkPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our School (Ages 2-12)"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kindergarten (Ages 2-5)"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elementary (Ages 5-12)"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STEAM Education"])},
        "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clubs & Activities"])}
      },
      "otherLink": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHERE NEXT ?"])},
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" STEAM"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security & Wellbeing"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our School"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Vision"])}
      }
    }
  },
  "submitting": {
    "tourSchool": {
      "subtitlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCHOOL TOURS"])},
      "linkList": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADMISSIONS"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCHOOL TOURS"])}
      },
      "contentPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choosing a school is one of the biggest decisions parents will make for their child, at the Royal School in Baghdad we appreciate how difficult it is. To support you in your choice, we invite all families to visit us for a fun tour around the school."])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no better way to understand the benefits of our curriculum than to come and see our school for yourself. Our receptionist will be happy to guide you around nursery, kindergarten and primary school, showing you our wonderful facilities and giving you a full explanation of them, they will also be able to answer For any questions you have and provide more details about the school's design, curriculum, and admissions process"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["book a tour either through "])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Website"])},
        "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or by "])},
        "item6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
        "item7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take a quick look at our school in"])},
        "item8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" School tour "])}
      },
      "titlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admissions"])},
      "linkPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admissions Process"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admission Fees"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Tours"])}
      },
      "otherLink": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHERE NEXT ?"])},
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admissions"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our School"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STEAM "])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])}
      }
    },
    "registerFees": {
      "subtitlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADMISSION FEES"])},
      "linkList": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADMISSIONS"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADMISSION FEES"])}
      },
      "contentPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The fees at the Royal School in Baghdad are inclusive of all educational services, textbooks and school uniforms unless otherwise stated. Tuition fees are paid in the form of easy installments over three installments."])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Parents who register more than one child will receive a 20% discount for each additional child that continues. Each year their children attend"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For more information, you can visit the school or call the following numbers:"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(+9647733332656 / +9647733332641)"])}
      },
      "titlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admissions"])},
      "linkPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admissions Process"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admission Fees"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Tours"])}
      },
      "otherLink": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHERE NEXT ?"])},
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admissions"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Tours"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Vision"])}
      }
    },
    "admissionProcedures": {
      "subtitlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADMISSIONS PROCESS"])},
      "linkList": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADMISSIONS"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADMISSIONS PROCESS"])}
      },
      "contentPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Royal School aims to create a new generation that is unique and ready for excellence and creativity in our modern world."])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" To achieve this goal, children who apply to study with us participate in a careful admission process, showing the extent of their comprehension, the strength of their observation and the nature of their behaviour."])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admission Requirements :"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before applying to the Royal School, we require that all students receive the appropriate vaccinations for their ages, and this must be proven with a health certificate."])},
        "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All pupils also need to attend an interview at the school and take the relevant exams :- "])},
        "item6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" - Academic Exam:"])},
        "item7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic tests are taken for all applicants before they are accepted into the Royal School."])},
        "item8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The required tests vary according to the age group: "])},
        "item9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Children applying for admission to Kindergarten 1 must only complete a point test."])},
        "item10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All other ages are subject to academic tests in English, science and mathematics."])},
        "item11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- psychological test:"])},
        "item12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We also require all children to take a psychological test that helps diagnose any mental health conditions or additional educational needs, for example autism. Only students who pass this test will be able to enroll in the school"])},
        "item13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Questionnaire:"])},
        "item14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parents are asked to fill out a questionnaire regarding the student's health condition and information on the number of family members and the child's sequence in the family."])}
      },
      "titlePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admissions"])},
      "linkPage": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admissions Process"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admission Fees"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Tours"])}
      },
      "otherLink": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHERE NEXT ?"])},
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fees"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Tours "])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development"])}
      }
    }
  },
  "posts": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related Posts"])}
  },
  "titleSection": {
    "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a tour around the school"])},
    "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School News"])},
    "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["more stories"])},
    "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Family"])}
  },
  "home": {
    "TitleHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invest in your children's future"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to the royal school of Baghdad. Where you invest the best in your child’s future. A unique school that presents a high-quality education for students aged from 2-to 12 through exceptional educational services. The royal school in Baghdad aims to develop kids’ intellectual abilities through 10 years and prepare them for the next stage of their education."])},
    "content": {
      "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welocom"])},
      "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From the Executive Director of the Royal School"])},
      "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haider Ghafoori"])}
    }
  },
  "slide1": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why Royal School ?"])},
    "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" To ensure that we are raising a generation capable of adapting to our fast-changing world and bringing about evolution, we support our education approach with a set of five core values:  Sense of Community 'Integrity','Leadership','Wisdom','Respectful'Our professional passionate teachers work on making kids individuals who are not scared of leadership."])},
    "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our vision is to provide a quality education that enables children to follow their own path and prepares them to overcome the challenges of tomorrow."])},
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rateUs"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ourvision"])}
  },
  "slide2": {
    "name": {
      "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alia Abdul Amir"])},
      "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tahany saad"])}
    },
    "title": {
      "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CEO"])},
      "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reception"])}
    },
    "content": {
      "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One of the first individuals that were and still are happy to be joining the royal school. We started it by planning the school’s vision and message many years ago. My duty as a principal is to lead our staff and students to creativity and excellence."])},
      "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My multitasking skills as a receptionist at the royal school are interactive communication with parents and guests and my organizing abilities are what make me distinguished at the royal school.  "])}
    }
  },
  "library": {
    "nursery": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nursery"])},
      "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 to 6 years old"])}
    },
    "kindergarten": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kindergarten"])},
      "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 to 6 years old"])}
    },
    "primary": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary"])},
      "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 to 12 years old"])},
      "classes": {
        "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Class "])},
        "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second Class"])},
        "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Class"])},
        "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fourth Class"])},
        "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fifth Class "])},
        "item6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sixth Class "])}
      }
    },
    "steam": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Method STEAM "])}
    },
    "teachers": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The teachers and students"])}
    },
    "parents": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parents' opinion"])}
    }
  },
  "form": {
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who do you wish to contact?"])},
    "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email address"])},
    "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phone number"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["title"])},
    "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mr"])},
    "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ms"])},
    "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miss"])},
    "item6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Doctor"])},
    "item7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Professor"])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
    "title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second Name"])},
    "title5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["family name"])},
    "title6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
    "title7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phone number"])},
    "title8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address of residence"])},
    "title9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the message"])}
  },
  "jobRequest": {
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["full name"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["marital status"])},
    "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["married"])},
    "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["single"])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
    "title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["required job"])},
    "title5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Academic Achievement"])},
    "title6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phone number"])},
    "title7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
    "title8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "title9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address of residence"])}
  }
}