<template>
    <div>
        <section class="pageNews">
            <v-container fluid>
            <v-row class="justify-end">
          <v-col cols="4">
            <form class="search">
              <div class="search-icon"></div>
              <input id="filter" type="text" name="q">
            </form>
          </v-col>
        </v-row>
        
                <v-row>
                    <v-col cols="12">
                        <div class="grids d-flex flex-row flex-wrap justify-center">
                        </div>
                        <br><br>
                    </v-col>
                </v-row>
                <br>
            </v-container>
            <div class="viewNews">
                <div class="closeSlide"><img src="image/closeSlide.svg"/></div>
                <div class="embedInstagram" id="embedInstagram">

                </div>
                <div class="otherLink">
                    <h1>{{ $t('posts.title') }}</h1>
                     <div class="morePost"></div>
                </div>
            </div>

        </section>
    </div>
</template>
<script>

import $ from "jquery";
import { popVideoLibrrary } from './../func.js'

export default {
  data() {
    return {
      options: {
        year: "numeric",
        month: "long",
        day: "numeric",
      },
    };
  },  
  mounted() {
    $(document).ready(function () {
      var urlVideo =
        "https://faya.dev/apiProjections/public/api/posts?storeId=3&type=Events";

      loadVideo();
      function loadVideo() {
        $.getJSON(urlVideo, function (data) {
          var linkUrl = data.data[0].linkUrl;
          var getId = linkUrl.split("/tv/");
          var viewـid = getId[getId.length - 1].split("/")[0].split("?")[0];
          var name_id = data.name;
          popVideoLibrrary(viewـid);
          mainVidLibrrary(data);
          morePost(name_id)
        });
      }

      function morePost(name_id){
        const api = `https://faya.dev/apiProjections/public/api/posts?storeId=3&type=Events&subDescription=${name_id}`;
         $.getJSON(api, function (item) {
          var linkUrl = item.data[0].linkUrl;
          var getId = linkUrl.split("/tv/");
          var viewـid = getId[getId.length - 1].split("/")[0].split("?")[0];
          popVideoLibrrary(viewـid);
            getMore(item)
         });
      }
      function getMore(item){
           $.each(item.data, function (i, elment) {
          var imgvid = elment.coverImage;
           var vid = elment.linkUrl;
          var names = elment.name;
          var subDescription = elment.subDescription;
          var date = elment.created_at;
          const event = new Date(date);
          const options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          };
         
          $(".morePost").append(`
                             <span class="CardmorePost wow fadeInUp" data-key="${vid}" data-wow-duration='3s' data-wow-delay='1s'>
                                    <img src="${imgvid}"/>
                                    <div class="overlay"></div>
                                    <div class="contentCard">
                                        <div class="d-flex flex-row align-center">
                                            <li>instagram</li>
                                            <li>
                                                <div class="lineCard"
                                                    style="{backgroundColor: '#' + NewsPost.color }"></div>
                                            </li>
                                        </div>
                                        
                                        <div class="d-flex flex-column items-center">
                                            <p style="font-size:35px">
                                               ${names}
                                            </p>
                                            <p class="name">
                                              ${subDescription}
                                            </p>
                                            <li>
                                                <div class="dataCard">
                                                    ${event.toLocaleDateString(
                                                      "ar-iq",
                                                      options
                                                    )}
                                                </div>
                                            </li>
                                        </div>
                                    </div>
                            <div class="hoverCrad">
                                    <ul class="d-flex flex-column align-center">
                                        <li>عرض المنشور</li>
                                    </ul>
                            </div>
                                
                                </span>
 `);

           });
            
      }

      function mainVidLibrrary(data) {
        $.each(data.data, function (i, data) {
          var imgvid = data.coverImage;
          var vid = data.linkUrl;
          var name = data.name;
          var subDescription = data.subDescription;
          var date = data.created_at;
          const event = new Date(date);


          const options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          };
          $(".grids").append(`
                             <span data-key="${vid}" data-title="${subDescription}" class="flexCard wow fadeInUp" data-wow-duration='3s' data-wow-delay='1s'>
                                    <img src="${imgvid}">
                                    <div class="overlay"></div>
                                    <div class="contentCard">
                                        <div class="d-flex flex-row align-center">
                                            <li>instagram</li>
                                            <li>
                                                <div class="lineCard"
                                                    style="{backgroundColor: '#' + NewsPost.color }"></div>
                                            </li>
                                        </div>
                                        
                                        <div class="d-flex flex-column items-center">
                                            <p style="font-size:35px">
                                               ${name}
                                            </p>
                                            <p class="name">
                                              ${subDescription}
                                            </p>
                                            <li>
                                                <div class="dataCard">
                                                    ${event.toLocaleDateString(
                                                      "ar-iq",
                                                      options
                                                    )}
                                                </div>
                                            </li>
                                        </div>
                                    </div>
                            </div>
                            <div class="hoverCrad">
                                    <ul class="d-flex flex-column align-center">
                                        <li>عرض المنشور</li>
                                    </ul>
                            </div>
                                
                                </span>
 `);

             });
             $("#filter").on("keyup", function () {
            var value = $(this).val().toLowerCase();
            $(".grids span").filter(function () {
              $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
            });
          });
               $(".grids").on("click", "span", function () {
          var id = $(this).attr("data-key"); // get video
          var name = $(this).attr("data-title");
          popVideoLibrrary(id);
          morePost(name);
          $(".viewNews").css("visibility", "visible");
          $(".viewNews").css("transform", "translateX(0px)");
          $(".viewNews").css("opacity", "1");
          $("._navleft.sticky").css("display", "none");
          $("._navright.sticky").css("display", "none");
          $("._navleft").css("display", "none");
          $("._navright").css("display", "none");
           $(".logoRoyalschool").css("display","none");
        });

         $(".morePost").on("click", "span", function () {
          var id = $(this).attr("data-key"); // get video
          popVideoLibrrary(id);
        });

        $(".closeSlide").on("click", function () {
 
          $(".viewNews").css("visibility", "hidden");
          $(".viewNews").css("transform", "translateX(100px)");
          $(".viewNews").css("opacity", "0");
          $("._navleft.sticky").css("display", "block");
          $("._navright.sticky").css("display", "block");
          $("._navleft").css("display", "block");
          $("._navright").css("display", "block");
           $(".logoRoyalschool").css("display","flex");
          $('#embedInstagram').empty(); 
        });
      }
    });
  },
};
</script>
