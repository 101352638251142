<template>
    <div>
        <section class="Pages">
            <header class="pageHeader">
                <div class="sub-TitlePage">{{ $t('family.developer.subtitlePage') }}</div>
                <ul class="linkList d-flex flex-row">
                    <li>
                        <router-link :to="`/`">{{ $t('family.developer.linkList.item1') }}</router-link>
                    </li>
                    <li class="line"></li>
                    <li>
                        <router-link to="">{{ $t('family.developer.linkList.item2') }}</router-link>
                    </li>
                    <li class="line"></li>
                    <li>
                        <router-link to="">{{ $t('family.developer.linkList.item3') }}</router-link>
                    </li>
                </ul>
                 <div class="overlay"></div>
                <img class="imgHeader" src="image/image-from-rawpixel-id-31304-jpeg.webp" alt="" lazy="loading"/>
                <img class="shareIcon" src="image/share.svg" width="100%" @click="hoverShare"/>
                <div class="shareList" :class="{'slide':slide}">
                 <ul>
                 <li> <a href="http://twitter.com/share?url=https://www.theroyalschool.com/Developer"  class="twitter">Twitter</a></li>
                <li> <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.theroyalschool.com/Developer" target="_blank" class="facebook">Facebook</a></li>
                <li> <a href="mailto:?subject=I wanted you to see this site&amp;body=Check out this site https://www.theroyalschool.com/Developer" class="email">Email Link</a></li>
                </ul>
                </div>
            </header>

            <div class="contentPage">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" md="9">
                            <ul class="d-flex flex-column align-start">
                                <li class="titleContent mfont">
                                 {{ $t('family.developer.contentPage.item1') }}
                                </li>
                                <li class="textContent">
                                {{ $t('family.developer.contentPage.item2') }}
                                </li>
                                 <li class="textContent">
                               {{ $t('family.developer.contentPage.item3') }}
                                 </li>
                                 <li class="textContent">
                               {{ $t('family.developer.contentPage.item4') }}
                                 </li>
                            </ul>
                        </v-col>
                        <v-col cols="12" md="3" class="d-flex flex-column justify-end">
                            <div class="titlePage">
                                <ul class="d-flex flex-column align-end">
                                    <li>
                                        <router-link to="">{{ $t('family.developer.titlePage') }}</router-link>
                                    </li>
                                </ul>
                            </div>
                            <div class="navPage">
                 
                                <ul class="d-flex flex-column align-end">
                                    <li class="linkPage">
                                        <router-link  :to="`/ParentsSociety`">{{ $t('family.developer.linkPage.item1') }}</router-link>
                                    </li>
                                    <li class="linkPage activeLink">
                                        <router-link  :to="`/Developer`">{{ $t('family.developer.linkPage.item2') }}</router-link>
                                    </li>
                                    <li class="linkPage">
                                        <router-link  :to="`/Jobs`">{{ $t('family.developer.linkPage.item3') }}</router-link>
                                    </li>
                                </ul>
                            </div>
                            <div class="otherLink">
                            <p>{{ $t('family.developer.otherLink.title') }}</p>
                                <ul class="d-flex flex-column align-end">
                                    <li>
                                        <ul class="d-flex flex-row align-center">
                                            <li>
                                                <router-link  :to="`/ParentsSociety`">
                                                    <p>{{ $t('family.developer.otherLink.item1') }}</p>
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link  :to="`/ParentsSociety`">
                                                    <div class="img"><img src="image/user-group-solid.svg"/></div>
                                                </router-link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul class="d-flex flex-row">
                                            <li>
                                                <router-link  :to="`/OurVision`">
                                                    <p>{{ $t('family.developer.otherLink.item1') }}</p>
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link :to="`/OurVision`">
                                                    <div class="img"><img src="image/telescope-svgrepo-com.svg"/></div>
                                                </router-link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul class="d-flex flex-row">
                                            <li>
                                                <router-link :to="`/developer`">
                                                    <p>{{ $t('family.developer.otherLink.item1') }}</p>
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link :to="`/developer`">
                                                    <div class="img"><img src="image/school-svgrepo-com.svg"/></div>
                                                </router-link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul class="d-flex flex-row">
                                            <li>
                                                <router-link :to="`/SafetySecurity`">
                                                    <p>{{ $t('family.developer.otherLink.item1') }}</p>
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link :to="`/SafetySecurity`">
                                                    <div class="img"><img src="image/security-camera.svg"/></div>
                                                </router-link>
                                            </li>
                                        </ul>

                                    </li>
                                </ul>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </section>
    </div>

</template>
<script>
  export default {
    data() {
      return {
          slide:null
      }
    },
    methods:{
        hoverShare() {
          this.slide = !this.slide; 
        }
    },
  }
</script>
