<template>
    <div class="slideSection" id="slideSection">
        <v-container>
            <v-row>
                <v-col cols="12">
                    <div class="contentSlide">
                        <div>
                            <ul class="d-flex flex-row sm-flex-column align-center">
                                <li>
                                   <div  v-for="(item ,index) in data" :key="index"  class="imgSlide">
                                    <img v-bind:src="item.coverImage" v-if="item"/>
                                    </div>
                                   
                                </li>
                                <li class="content">
                                    <div class="textSlide">
                                        <ul class="d-flex flex-column align-items">
                                            <li>
                                                <div class="titleSection">{{ $t('slide1.title') }}</div>
                                            </li>
                                            <li class="d-flex flex-column">
                                                <div class="titles d-grid">
                                                    <div v-for="item in data" :key="item.id" style="display: grid;grid-area: -1/1;">
                                                        <h1 v-if="item"  class=" title">
                                                        {{ this.$i18n.locale=='ar'? item.subDescription:item.en_subDescription }}
                                                        </h1>
                                                    </div>
                                                </div>
                                                
                                                <div class="lineTitle"></div>
                                            </li>

                                            <li>
                                            <div v-for="item in data" :key="item.id" style="display: grid;grid-area: -1/1;">
                                                <span v-if="item" class="infoText"  id="colorDark">  
                                                        {{ this.$i18n.locale=='ar'? item.description:item.en_description }}
                                                </span>
                                                </div>
                                            </li>
                                            <li><router-link to="OurVision"><img src="image/more.svg"/></router-link></li>
                                        </ul>
                                    </div>

                                </li>
                            </ul>
                        </div>

                    </div>

                </v-col>
            </v-row>

        </v-container>
        <div class="controls-slide">
            <div class="prev" @click="prevSlide"><img src="image/prev.svg"/></div>
            <div class="next" @click="nextSlide"><img src="image/next.svg"/></div>
        </div>
  
    </div>
</template>
<script>
import axios from 'axios';
    export default {
        components: {
        },
        data() {
            return{
                dataIsReady: false,
                data:[]
            }
        },
        methods: {
            async getData(){
              await axios.get('https://faya.dev/apiProjections/public/api/posts?storeId=3&type=home')
                .then((res) => {
                   this.data = res.data.data;
                   this.dataIsReady = true;
                })
                .catch((error) => {
                    console.log(error)
                    })
            },

  //slide2
nextSlide () {
        let title = document.querySelectorAll(".titles .title");
        let img = document.querySelectorAll(".imgSlide");
        let infoText = document.querySelectorAll(".infoText");

        let indexImg, indexTitle, indexText = 0

        title.forEach((item, index) => {
            if (item.classList.contains('activeTitle')) {
                indexImg = index;
                indexTitle = index;
                indexText = index;
            }
        })
        title[indexTitle].classList.remove('activeTitle');
        img[indexImg].classList.remove('activeImg');
        infoText[indexText].classList.remove('activeText');

        indexTitle = (indexTitle + 1) % title.length;
        indexImg = (indexImg + 1) % img.length;
        indexText = (indexText + 1) % infoText.length;

        title[indexTitle].classList.add('activeTitle');
        img[indexImg].classList.add('activeImg');
        infoText[indexText].classList.add('activeText');
},
prevSlide(){
    let title = document.querySelectorAll(".titles .title");
    let img = document.querySelectorAll(".imgSlide");
    let infoText = document.querySelectorAll(".infoText");
    
    let indexImg, indexTitle, indexText = 0
    title.forEach((item, index) => {
        if (item.classList.contains('activeTitle')) {
            indexImg = index;
            indexTitle = index;
            indexText = index;
            
        }
    })

    title[indexTitle].classList.remove('activeTitle');
    img[indexImg].classList.remove('activeImg');
    infoText[indexText].classList.remove('activeText');

    indexTitle = (indexTitle - 1 + title.length) % title.length;
    indexImg = (indexImg - 1 + img.length) % img.length;
    indexText = (indexText - 1 + infoText.length) % infoText.length;

    title[indexTitle].classList.add('activeTitle');
    img[indexImg].classList.add('activeImg');
    infoText[indexText].classList.add('activeText');
},
autoplay(){
        let title = document.querySelectorAll(".titles .title");
        let img = document.querySelectorAll(".imgSlide");
        let infoText = document.querySelectorAll(".infoText");
        let indexImg=  0;
        let  indexTitle =0;
        let indexText = 0;
        
        title[indexTitle].classList.add('activeTitle');
        img[indexImg].classList.add('activeImg');
        infoText[indexText].classList.add('activeText');
        setInterval(function () {
            title[indexTitle].classList.remove('activeTitle');
            img[indexImg].classList.remove('activeImg');
            infoText[indexText].classList.remove('activeText');

            indexTitle = (indexTitle + 1) % title.length;
            indexImg = (indexImg + 1) % img.length;
            indexText = (indexText + 1) % infoText.length;

            title[indexTitle].classList.add('activeTitle');
            img[indexImg].classList.add('activeImg');
            infoText[indexText].classList.add('activeText');
        },3000);
}

        },
        
         async mounted() {
            await this.getData();
             this.autoplay();
         }
 }
</script>

