<template>
<section class="sectionLibrary">
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" class="d-flex justify-end sm-justify-center lineSlant">
                                <router-link :to="`/KG1`" class="card">
                                    <img src="image/11.webp"/>
                                    <div class="overlay"></div>
                                    <ul class="d-flex flex-column justify-center items-center content-center">
                                        <li>kg1</li>
                                    </ul>
                                </router-link>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" class="d-flex justify-center mb sm-justify-center">
                                <router-link :to="`/KG2`" class="card">
                                    <img src="image/22.webp"/>
                                     <img src="image/22.webp"/>
                                    <div class="overlay"></div>
                                    <ul class="d-flex flex-column justify-center items-center content-center">
                                        <li>kg2</li>
                                    </ul>
                                </router-link>
                            </v-col>
                        </v-row>
                        
                    </v-container>
                </v-col>
            </v-row>
           
        </v-container>
    </section>

</template>