<template>
    <div class="navgation">
         <router-link to="/" class="logoRoyalschool"><img src="image/logo.webp" alt="logo" loading="lazy" width="100%"/></router-link>
        <div class="_navleft">
            <div class="slide-menu" @click="toggleNave" :class="{'open':activeNave}">
                <a>{{ $t('nav.Menu.menuName') }}</a>
                <div class="IconMenu">
                    <div class="line1"></div>
                    <div class="line2"></div>
                    <div class="line3"></div>
                </div>
            </div>
            <!-- submenu nav -->
            <div class="subMenu">
                <div class="contentMenu" :class="{'activeNave':activeNave}">
                    <v-container>
                        <v-row class="">
                            <v-col cols="9" md="9" class="text-right">
                                <ul class="menu-subNav">
                                    <li class="activeLink" @click="toggleNave">
                                        <router-link to="/">
                                        {{ $t('nav.Menu.home') }}
                                        </router-link>
                                    </li>
                                    <li class="activeLink" v-on:mouseover="subAbout">
                                        <router-link to=""><img src="image/leftIcon.webp"/>
                                        {{ $t('nav.Menu.aboutUs') }}
                                        </router-link>
                                    </li>
                                    <li class="activeLink" v-on:mouseover="subShool">
                                        <router-link to=""><img src="image/leftIcon.webp"/>
                                        {{ $t('nav.Menu.schooLife') }}
                                        </router-link>
                                    </li>
                                    <li class="activeLink" v-on:mouseover="subSubmit">
                                        <router-link to=""><img src="image/leftIcon.webp"/>
                                        {{ $t('nav.Menu.admissions') }}
                                        </router-link>
                                    </li>
                                    <li class="activeLink" v-on:mouseover="subFamily">
                                        <router-link to=""><img src="image/leftIcon.webp"/>
                                        {{ $t('nav.Menu.ourCommunity') }}
                                        </router-link>
                                    </li>
                                </ul>
                            </v-col>
                            <v-col cols="3" md="3" display="flex" style="display: flex;justify-content: flex-end;">
                                <ul class="menu-contact">
                                    <li @click="toggleNave">

                                        <router-link :to="`/ContactUs`">
                                            <div class="icons">
                                                <img src="image/icon1.svg" alt=""/>
                                            </div>
                                            {{ $t('nav.Menu.contactUs') }}
                                        </router-link>
                                    </li>

                                    <li @click="toggleNave">

                                        <a href="https://www.google.com/maps?ll=33.333636,44.453524&z=4&t=m&hl=en&gl=RO&mapclient=embed&cid=16489200423650082501">
                                            <div class="icons">
                                                <img src="image/icon2.svg" alt=""/>
                                            </div>

                                             {{ $t('nav.Menu.visit') }}
                                        </a>
                                    </li>

                                    <li @click="toggleNave">

                                        <router-link :to="`/AdmissionProcedures`">
                                            <div class="icons">
                                                <img src="image/icon3.svg" alt=""/>
                                            </div>
                                             {{ $t('nav.Menu.apply') }}
                                        </router-link>
                                    </li>

                                    <li @click="toggleNave">

                                        <router-link :to="`/ContactUs`">
                                            <div class="icons">
                                                <img src="image/icon4.svg" alt=""/>
                                            </div>
                                             {{ $t('nav.Menu.enquiy') }}
                                        </router-link>
                                    </li>

                                </ul>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>

                <div class="SubMenu-Other" :class="{'active':active}" v-on:mouseleave="mouseleave">
                <div class="back" @click="backNave" ><img src="image/back.svg"/></div>
                    <ul class="linkSubMenu  d-flex flex-column" :class="{'subabout':subabout}">
                        <li @click="toggleNave">
                            <router-link :to="`/WelcomeMessage`" class="activeLink">{{ $t('nav.subMenu.aboutUs.welcome') }}</router-link>
                        </li>
                        <li @click="toggleNave">
                            <router-link :to="`/OurVision`">{{ $t('nav.subMenu.aboutUs.ourVision') }}</router-link>
                        </li>
                        <li @click="toggleNave">
                            <router-link :to="`/BuildSchool`">{{ $t('nav.subMenu.aboutUs.campusFacilities') }}</router-link>
                        </li>
                        <li @click="toggleNave">
                            <router-link  :to="`/SafetySecurity`">{{ $t('nav.subMenu.aboutUs.securityWellbeting') }}</router-link>
                        </li>
                    </ul>
                    <ul class="linkSubMenu d-flex flex-column" :class="{'subshool':subshool}">
                        <li @click="toggleNave">
                            <router-link   :to="`/PupilsAges`"  class="activeLink">{{ $t('nav.subMenu.schoolLife.pupilsAges') }}</router-link>
                        </li>
                        <li @click="toggleNave">
                            <router-link  :to="`/NurseryKindergarten`">{{ $t('nav.subMenu.schoolLife.kinderGarten') }}</router-link>
                        </li>
                        <li @click="toggleNave">
                            <router-link :to="`/Primary`">{{ $t('nav.subMenu.schoolLife.elementary') }}</router-link>
                        </li>
                        <li @click="toggleNave">
                            <router-link  :to="`/Steam`">{{ $t('nav.subMenu.schoolLife.steam') }}</router-link>
                        </li>
                        <li @click="toggleNave">
                            <router-link :to="`/ClubsActivities`">{{ $t('nav.subMenu.schoolLife.clubsActivities') }}</router-link>
                        </li>
                    </ul>
                    <ul class="linkSubMenu d-flex flex-column" :class="{'subsubmit':subsubmit}">
                        <li @click="toggleNave">
                            <router-link :to="`/AdmissionProcedures`" class="activeLink">{{ $t('nav.subMenu.admissions.admissionsProcess') }}</router-link>
                        </li>
                        <li @click="toggleNave">
                            <router-link :to="`/RegisterFees`">{{ $t('nav.subMenu.admissions.admissionsFees') }}</router-link>
                        </li>
                        <li @click="toggleNave">
                            <router-link :to="`/TourSchool`">{{ $t('nav.subMenu.admissions.schoolTours') }}</router-link>
                        </li>

                    </ul>
                    <ul class="linkSubMenu  d-flex flex-column" :class="{'subfamily':subfamily}">
                        <li @click="toggleNave">
                            <router-link  :to="`/ParentsSociety`" class="activeLink">{{ $t('nav.subMenu.ourCommunity.parentsCommunity') }}</router-link>
                        </li>
                        <li @click="toggleNave">
                            <router-link :to="`/Developer`" >{{ $t('nav.subMenu.ourCommunity.development') }}</router-link>
                        </li>
                        <li @click="toggleNave">
                            <router-link :to="`/Jobs`">{{ $t('nav.subMenu.ourCommunity.jobs') }}</router-link>
                        </li>

                    </ul>
                </div>

            </div>

            <!-- /submenu nav -->
        </div>
        <div class="_navright">

            <ul class="d-flex flex-row align-center" style="padding:0;">
                <li class="responsiveItem">
                    <router-link :to="`/ContactUs`">
                        <div class="iconConect">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 21.629 21.629">
                            <path id="Path_19" data-name="Path 19" d="M2,3.352A1.352,1.352,0,0,1,3.352,2h2.91A1.352,1.352,0,0,1,7.6,3.13l1,6a1.352,1.352,0,0,1-.73,1.433L5.773,11.6a14.92,14.92,0,0,0,8.253,8.253l1.046-2.093a1.352,1.352,0,0,1,1.432-.73l6,1a1.352,1.352,0,0,1,1.13,1.333v2.91a1.352,1.352,0,0,1-1.352,1.352h-2.7A17.573,17.573,0,0,1,2,6.055Z" transform="translate(-2 -2)"/>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 83.396 44.812">
                            <path id="Path_5" data-name="Path 5" d="M1530.436,80.478l39.486,38.427-39.486,41.453" transform="translate(162.082 -1528.625) rotate(90)" fill="none"  stroke-width="6"/>
                            </svg>
                        </div>
                        {{ $t('nav.faContact') }}
                    </router-link>
                    <div class="dropContact">
                        <ul>
                            <li class="d-flex">
                            <a href="https://www.google.com/maps?ll=33.333636,44.453524&z=4&t=m&hl=en&gl=RO&mapclient=embed&cid=16489200423650082501">
                                <div class="icons">
                                    <img src="image/icon2.svg" alt=""/>
                                </div>
                                {{ $t('nav.dropContact.visit') }}
                                </a>
                            </li>
                            <li class="d-flex">
                            <router-link :to="`/AdmissionProcedures`">
                                <div class="icons">
                                    <img src="image/icon3.svg" alt=""/>
                                </div>
                                {{ $t('nav.dropContact.apply') }}
                                </router-link>
                            </li>
                            <li class="d-flex">
                            <router-link :to="`/ContactUs`">
                                <div class="icons">
                                    <img src="image/icon4.svg" alt=""/>
                                </div>
                                {{ $t('nav.dropContact.enquiy') }}
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </li>
                <li class="item eventEn" @click="setLocale('en')">
                    <router-link to="">EN</router-link>
                </li>
                <li class="line item"></li>
                <li class="item eventAr" @click="setLocale('ar')">
                    <router-link to="" >عربي</router-link>
                </li>
                <li class="item">
                    <router-link to="/">{{ $t('nav.Menu.home') }}</router-link>
                </li>
                <li class="item">
                    <router-link to="/Library">{{ $t('nav.library') }}</router-link>
                </li>
                <li class="item">
                    <router-link to="/NewsSchool"> {{ $t('nav.events') }}</router-link>
                </li>


            </ul>
        </div>

    </div>
</template>
<script>
    import $ from "jquery";
    export default {
        data() {
            return {
                activeNave: false,
                active: false,
                subabout: false,
                subshool: false,
                subsubmit: false,
                subfamily: false,
            }
        },
        methods: {
            //setLocale(locale) {
                //this.$i18n.locale = locale
               // this.localStorage.setItem("language", locale);
                //this.$router.push({
                   // params: { lang: locale }
            //})
             setLocale(locale) {
                this.$i18n.locale = locale
                this.$router.push({
                    params: { lang: locale }
            })
            },
            backNave(){
                this.active = !this.active
            },
            toggleNave() {
                this.activeNave = !this.activeNave,
                 this.active = false
            },
            subAbout() {
                this.subabout = true,
                    this.subshool = false,
                    this.subsubmit = false,
                    this.subfamily = false,
                    this.active = true
            },
            subShool() {
                this.subshool = true,
                    this.subabout = false,
                    this.subsubmit = false,
                    this.subfamily = false
                    this.active = true
            },
            subSubmit() {
                this.subsubmit = true,
                    this.subabout = false,
                    this.subshool = false,
                    this.subfamily = false,
                    this.active = true
            },
            subFamily() {
                this.subfamily = true,
                    this.subabout = false,
                    this.subsubmit = false,
                    this.subshool = false,
                    this.active = true
            },
            mouseleave() {
                this.active = false
            }
        },
        mounted() {
        $(document).ready(function(){
          $(".eventEn").click(function(){
          $("body").addClass("ltr");
        });
          $(".eventAr").click(function(){
          $("body").removeClass("ltr");
        });
        });

            //active li on click hover
            $(document).ready(function () {
                $(".menu-subNav .activeLink").on("click", function () {
                    $(".menu-subNav .activeLink").removeClass("actvtion");
                    $(this).addClass("actvtion");
                });
                $(".linkSubMenu li a").on("click", function () {
                    $(".linkSubMenu li a").removeClass("activeLink");
                    $(this).addClass("activeLink");
                });
            });
        },
    }
</script>
