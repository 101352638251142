import { createI18n } from 'vue-i18n'
import store  from "./store.js";

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key).default
    }
  })
  return messages
}

export default  createI18n({
  legacy: false, // you must set `false`, to use Composition API
  locale:'ar',
  fallbackLocale:store.getters.getAppLanguage,
  messages: loadLocaleMessages(),
  globalInjection: true,inheritLocale: false

})


