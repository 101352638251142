<template>
    <section class="sectionLibrary">
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" class="d-flex justify-end sm-justify-center lineSlant">
                                <router-link :to="`/Nurser`"  class="card">
                                    <img src="image/card1.webp"/>
                                    <div class="overlay"></div>
                                    <ul class="d-flex flex-column justify-center items-center content-center">
                                        <li>{{ $t('library.nursery.title') }}</li>
                                        <li>{{ $t('library.nursery.years') }}</li>
                                    </ul>
                                </router-link>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" class="d-flex justify-center mb sm-justify-center lineSlant">
                                <router-link :to="`/Kindergarten`" class="card">
                                    <img src="image/card2.webp"/>
                                    <div class="overlay"></div>
                                    <ul class="d-flex flex-column justify-center items-center content-center">
                                        <li>{{ $t('library.kindergarten.title') }}</li>
                                        <li>{{ $t('library.kindergarten.years') }}</li>
                                    </ul>
                                </router-link>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" class="d-flex justify-start mb sm-justify-center">
                                 <router-link :to="`/Elementray`" class="card">
                                    <img src="image/card3.webp"/>
                                     <img src="image/card13.webp"/>
                                    <div class="overlay"></div>
                                    <ul class="d-flex flex-column justify-center items-center content-center">
                                       <li>{{ $t('library.primary.title') }}</li>
                                        <li>{{ $t('library.primary.years') }}</li>
                                    </ul>
                                </router-link>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" class=" d-flex justify-end sm-justify-center lineSlant">
                                <router-link  :to="`/Steam`" class="card">
                                    <img src="image/card4.webp"/>
                                    <div class="overlay"></div>
                                    <ul class="d-flex flex-column justify-center items-center content-center">
                                       <li>{{ $t('library.steam.title') }}</li>
                                    </ul>
                                </router-link>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" class="d-flex justify-center mb sm-justify-center lineSlant">
                                <router-link  :to="`/NewsSchool`" class="card">
                                    <img src="image/card5.webp"/>
                                    <div class="overlay"></div>
                                    <ul class="d-flex flex-column justify-center items-center content-center">
                                        <li>{{ $t('library.teachers.title') }}</li>
                                    </ul>
                                </router-link>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" class="d-flex justify-start mb sm-justify-center">
                               <router-link :to="`/NewsSchool`" class="card">
                                   <img src="image/card16.webp"/>
                                    <img src="image/card6.webp"/>
                                    <div class="overlay"></div>
                                    <ul class="d-flex flex-column justify-center items-center content-center">
                                        <li>{{ $t('library.parents.title') }}</li>
                                    </ul>
                                </router-link>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>
