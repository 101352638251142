<template>
  <v-container>
    <form class="d-flex flex-column " method="post">
      <ul>
        <li>
          <div>
          <label for="select" class="form-label">{{ $t('form.title1') }}</label>
            <select v-model="body.Contact">
              <option value="null" selected>{{ $t('form.item1') }}</option>
              <option value="">{{ $t('form.item2') }}</option>
            </select>
            <div class="error" v-if="validContact.length">
              <span v-for="error in validContact" :key="error.id">{{ error }}</span>
            </div>
          </div>
        </li>
        <li>
          <div class="d-flex flex-row d-flex-sm">
            <div>
            <label for="select" class="form-label">{{ $t('form.title2') }}</label>
              <select v-model="body.Address" class="mWidth">
                <option value="null" selected>{{ $t('form.item3') }}</option>
                <option value="">{{ $t('form.item4') }}</option>
                <option value="">{{ $t('form.item5') }}</option>
                <option value="">{{ $t('form.item6') }}</option>
                <option value="">{{ $t('form.item7') }}</option>
              </select>
              <div class="error" v-if="validAddress.length">
                <span v-for="error in validAddress" :key="error.id">{{ error }}</span>
              </div>
            </div>
            <div>
            <label for="text" class="form-label">{{ $t('form.title3') }}</label>
              <input type="text" class="mWidth" name="Fname" v-model="body.Fname">
              <div class="error" v-if="validFname.length">
                <span v-for="error in validFname" :key="error.id">{{ error }}</span>
              </div>
            </div>
          </div>
        </li>

        <li>
          <label for="text" class="form-label">{{ $t('form.title4') }}</label>
          <input type="text" name="Sname" v-model="body.Sname" />
          <div class="error" v-if="validSname.length">
            <span v-for="error in validSname" :key="error.id">{{ error }}</span>
          </div>
        </li>

        <li>
        <label for="text" class="form-label">{{ $t('form.title5') }}</label>
          <input type="text" id="inputField" name="Lname" v-model="body.Lname" placeholder="" />
          <div class="error" v-if="validLname.length">
            <span v-for="error in validLname" :key="error.id">{{ error }}</span>
          </div>
        </li>
        <li>
        <label for="email" class="form-label">{{ $t('form.title6') }}</label>
          <input type="email" name="Email" v-model="Email">
          <div class="error" v-if="validEmail.length">
            <span v-for="error in validEmail" :key="error.id">{{ error }}</span>
          </div>
        </li>

        <li>
        <label for="text" class="form-label">{{ $t('form.title7') }}</label>
          <input type="text" name="PhNumber" v-model="body.PhNumber">
          <div class="error" v-if="validPhNumber.length">
            <span v-for="error in validPhNumber" :key="error.id">{{ error }}</span>
          </div>
        </li>
        <li>
        <label for="text" class="form-label">{{ $t('form.title8') }}</label>
        <br>
          <input type="text" name="Subject" v-model="body.Subject">
          <div class="error" v-if="validSubject.length">
            <span v-for="error in validSubject" :key="error.id">{{ error }}</span>
          </div>
        </li>

        <li>
        <label for="text" class="form-label">{{ $t('form.title9') }}</label>
        <br>
          <textarea rows="5" cols="20" v-model="body.Textarea" />
          <div class="error" v-if="validTextarea.length">
          <span v-for="error in validTextarea" :key="error.id">{{ error }}</span>
        </div>
      </li>

        <div>
          <br>
          <button id="btn" :class="{'active':active}" @click.prevent="submitForm">
            <p id="btnText">{{textBtn}}</p>
            <div class="check-box">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                <path fill="transparent" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
              </svg>
            </div>
          </button>
        </div>
      </ul>
    </form>
  </v-container>

</template>

<script>
 import axios from "axios";
  export default {
    data() {
      return {
        textBtn: "Submit",
        active: false,
        trans: false,
        validContact:[],
        validAddress:[],
        validFname:[],
        validSname: [],
        validLname:[],
        validEmail:[],
        validPhNumber:[],
        validSubject:[],
        validTextarea:[],
        dataForm:[],
        Email:null,
        body: {
          Contact:null,
          Fname: null,
          Sname: null,
          Lname: null,
          PhNumber:'',
          Subject:null,
          Address: null,
          Textarea:null
        }
      }
    },
    methods: {
      async submitForm(e) {
        var textWord =
          /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_]*$/;
        var re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        var maxnum = 11;

        this.validContact = [];
        this.validAddress = [];
        this.validFname = [];
        this.validSname = [];
        this.validLname = [];
        this.validEmail = [];
        this.validPhNumber = [];
        this.validSubject = [];
        this.validTextarea = [];

        if(this.body.Contact === null) {
            this.validContact.push('This field is required *');
        }else if(this.body.Address === null) {
            this.validAddress.push('This field is required  *');
        }else if(this.body.Fname === null) {
            this.validFname.push('This field is required *');
        }else if(this.body.Fname === '') {
            this.validFname.push('The field is empty, please enter your first name *');
        }else if(!textWord.test(this.body.Fname)) {
            this.validFname.push('The field must contain only alphanumeric characters (a-z) *');
        }else if (this.body.Sname === null) {
          this.validSname.push('This field is required  *');
        } else if (this.body.Sname === '') {
          this.validSname.push('The field is empty, please enter your second name *');
        }else if (!textWord.test(this.body.Sname)) {
          this.validSname.push('The field must contain only alphanumeric characters (a-z) *');
        }else if(this.body.Lname === null) {
            this.validLname.push('This field is required *');
        }else if(this.body.Lname === '') {
            this.validLname.push('The field is empty, please enter your last name *');
        }else if(!textWord.test(this.body.Lname)) {
            this.validLname.push('The field must contain only alphanumeric characters (a-z) *');
        }else if(this.Email === null) {
          this.validEmail.push('This field is required *');
        }else if (this.Email === '') {
            this.validEmail.push('The field is empty, please enter your email *');
        }else if (!re.test(this.Email)) {
            this.validEmail.push('Please enter a valid email address (example@domain.com) *');
        }else if(this.body.PhNumber === null) {
            this.validPhNumber.push('This field is required *');
        }else if(this.body.PhNumber === '') {
            this.validPhNumber.push('This field is required *');
        }else if((this.body.PhNumber.length > maxnum)||((this.body.PhNumber).length < maxnum)) {
            this.validPhNumber.push('Your phone number is invalid *');
        }else if(this.body.Subject === null) {
            this.validSubject.push('This field is required *');
        }else if(this.body.Subject === '') {
            this.validSubject.push('The field is empty, please enter your last name *');
        }else if(!textWord.test(this.body.Subject)) {
            this.validSubject.push('The field must contain only alphanumeric characters (a-z) *');
        }else if(this.body.Textarea === null) {
            this.validTextarea.push('This field is required *');
        }else if(this.body.Textarea === '') {
            this.validTextarea.push('The field is empty, please enter the text of the message *');
        }else{
                this.textBtn = 'Thanks';
                this.active = true;
               
                axios.post(`https://faya.dev/apiProjections/public/api/contactUs`, {
                    store_id: 3,
                    email:this.Email,
                    body:JSON.stringify(this.body),
                }).then(response => {
                    const dataContent = JSON.parse(response.dataContent);
                    this.dataForm.push(dataContent);
                });
                 if(this.active === true) {
                    this.Address = null,
                    this.Email = null,
                    this.body.Contact = null,
                    this.body.Fname = null,
                    this.body.Sname = null,
                    this.body.Lname = null,
                    this.body.PhNumber = '',
                    this.body.Subject = null,
                    this.body.Textarea = null
                   }
            }
            e.preventDefault();
      }
    },
  }
</script>