import $ from 'jquery'

export function popVideoLibrrary(viewـid, html = '#embedInstagram') {
  var getId;
  var viewـid2;
  console.log(viewـid);

  if (viewـid.includes('instagram')) {
    if (viewـid.includes('/tv/')) getId = viewـid.split("/tv/");
    else if (viewـid.includes('/p/')) getId = viewـid.split("/p/");
    else if (viewـid.includes('/reel/')) getId = viewـid.split("/reel/");
    viewـid2 = getId[getId.length - 1].split("/")[0].split("?")[0];
    console.log(viewـid2);
    $(html).html(`
                <iframe src="https://www.instagram.com/p/${viewـid2}/embed" width="100%" height="500" frameborder="0" scrolling="no" allowtransparency="true"></iframe>
              `);
  }
  else if (viewـid.includes('facebook')) {
    if (viewـid.includes('/videos/')) getId = viewـid.split("/videos/");
    else if (viewـid.includes('/watch/?v=')) getId = viewـid.split("/watch/?v=");

    viewـid2 = getId[getId.length - 1].split("/")[0].split("?")[0];
    console.log(viewـid2);

    $(html).html(`
                <iframe src="http://www.facebook.com/video/embed?video_id=${viewـid2}" width="100%" height="500" frameborder="0" scrolling="no" allowtransparency="true"></iframe>
              `);

  }

  else if (viewـid.includes('fb.watch')) {
    if (viewـid.includes('watch/')) getId = viewـid.split(".watch/");

    viewـid2 = getId[getId.length - 1].split("/")[0].split("?")[0];
    console.log(`http://www.facebook.com/video/embed?video_id=${viewـid2}`);



    $(html).html(`
              <iframe src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Ffb.watch%2F${viewـid2}" width="100%" height="500" frameborder="0" scrolling="no" allowtransparency="true"></iframe>
            `);
  }
  /** 
  else if (viewـid.includes('vm.tiktok')) {
    if (viewـid.includes('com/')) getId = viewـid.split(".com/");
console.log("https://www.tiktok.com/oembed?url=https://vm.tiktok.com/ZMNgr5JYq");
    viewـid2 = getId[getId.length - 1].split("/")[0].split("?")[0];
    $(html).html(`
             <iframe src="https://www.tiktok.com/oembed?url=https://vm.tiktok.com/ZMNgr5JYq" width="100%" height="500" frameborder="0" scrolling="no" allowtransparency="true"></iframe>
           `);
  }
*/
  else if (viewـid.includes('tiktok')) {
    if (viewـid.includes('/video/')) getId = viewـid.split("/video/");

    viewـid2 = getId[getId.length - 1].split("/")[0].split("?")[0];
    console.log(`https://www.tiktok.com/embed/v2/${viewـid2}`);


    $(html).html(`
             <iframe src="https://www.tiktok.com/embed/v2/${viewـid2}" width="100%" height="500" frameborder="0" scrolling="no" allowtransparency="true"></iframe>
           `);
  }
}
