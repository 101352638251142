<template>
    <div class="footer">
        <div>
            <v-container fluid>
                <v-row>
                    <v-col cols="12" md="6" sm="12">
                        <ul>
                            <li>
                                <p> {{ $t('footer.visit') }} </p>
                            </li>
                            <li> <img src="image/mapsIcon.webp" alt="" style="margin-left: 10px; width:20px;margin-bottom: -5px;"/>
                              {{ $t('footer.address') }} 
                              </li>
                            <li>
                            <br>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d27307483.092723466!2d44.45352400000001!3d33.333636!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe4d5686bf9e23ec5!2z2KfZhNmF2K_Ysdiz2Kkg2KfZhNmF2YTZg9mK2Kk!5e0!3m2!1sen!2sro!4v1650489256418!5m2!1sen!2sro" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                            </iframe>
                          
                            </li>
                            <li>
                                <a href="https://www.google.com/maps?ll=33.333636,44.453524&z=4&t=m&hl=en&gl=RO&mapclient=embed&cid=16489200423650082501">{{ $t('footer.sitemap') }}</a>
                            </li>
                        </ul>

                    </v-col>
                    <v-col cols="12" md="6" sm="12" class=" text-left">
                        <ul>
                            <li>
                                <p>{{ $t('footer.follow') }}</p>
                            </li>
                            <li>
                                <div class="d-flex align-center sm-flex-wrap">
                                    <a href="https://www.youtube.com/channel/UCLVgR0hFLXxrxMLSYSAYlvQ"><img src="image/youtubeIcon.webp" alt="" style="width:45px;padding-left: 10pX;"/>
                                    </a>
                                    <a href="https://twitter.com/RoyalSchBaghdad"><img src="image/twitterIcon.webp" alt="" style="width:45px;padding-left: 10px;"/>
                                    </a>
                                    <a href="https://www.tiktok.com/@theroyalschooliraq"><img src="image/tiktokIcon.webp" alt="" style="width:40px;padding-left: 10px;"/>
                                    </a>
                                    <a href="https://www.instagram.com/RSBaghdad/"><img src="image/instagramIcon.webp" alt="" style="width:35px;padding-left: 10px;"/>
                                    </a>
                                    <a href="https://www.facebook.com/TheRoyalSchool123"><img src="image/facebookIcon.webp" alt="" style="width:28px;padding-left: 10px;"/>
                                    </a>
                                    <router-link dir="rtl" to="">ROYALSCHBAGHDAD@</router-link>
                                </div>
                            </li>
                            <li class="d-flex flex-column align-start">
                                <p>{{ $t('footer.contactUs') }}</p>
                                <ul>
                                    <li class="mt-5">
                                    <img src="image/phoneIcon.webp" alt=""
                                    style="margin-inline-end:8px; width: 20px;"/>
                                        <a href="tel:+96407733332656" dir="rtl">2656 3333 077</a>
                                    </li>
                                    <li class="mt-5">
                                    <img src="image/phoneIcon.webp" alt=""
                                    style="margin-inline-end:8px; width: 20px;"/>
                                        <a href="tel:+96407733332641" dir="rtl">2641 3333 077</a>
                                    </li>
                                    <li class="mt-5">
                                    
                                    <img src="image/Messages.webp" alt=""
                                    style="margin-inline-end:10px; width: 18px;"/>
                                        <a href="https://wa.me/+96407733332642" dir="rtl">2642 3333 077</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <ul class="marginTop buttonOther d-flex flex-row align-start">
                            <li>
                                <router-link to="/AdmissionProcedures">{{ $t('footer.apply') }}</router-link>
                            </li>
                            <li>
                                <router-link to="/ContactUs">{{ $t('footer.enquiy') }}</router-link>
                            </li>
                            <li>
                                <a href="https://www.google.com/maps?ll=33.333636,44.453524&z=4&t=m&hl=en&gl=RO&mapclient=embed&cid=16489200423650082501">{{ $t('footer.visit') }}</a>
                            </li>
                        </ul>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <div class="copyright">
            <div><a href="https://faya.dev/#/">Design by FayaDev#</a></div>
            <div> {{ $t('footer.terms') }} </div>
        </div>
    </div>
</template>
