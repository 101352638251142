import Vuex from 'vuex';

export default new Vuex.Store({
  state: {
    appLanguage: localStorage.getItem("appLanguage") || process.env.VUE_APP_I18N_LOCALE || 'ar'
  },
  getters: {
    getAppLanguage: (state) => state.appLanguage
  },
  mutations: {
    setAppLanguage(state, language) {
      state.appLanguage = language;
      localStorage.setItem("language", language); // Whenever we change the appLanguage we save it to the localStorage
    }
  }
})
