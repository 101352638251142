<template>
    <div>
        <section class="Pages">
            <header class="pageHeader">
                <div class="sub-TitlePage">{{ $t('family.jobs.subtitlePage') }}</div>
                <ul class="linkList d-flex flex-row">
                    <li>
                        <router-link :to="`/`">{{ $t('family.jobs.linkList.item1') }}</router-link>
                    </li>
                    <li class="line"></li>
                    <li>
                        <router-link to="">{{ $t('family.jobs.linkList.item2') }}</router-link>
                    </li>
                    <li class="line"></li>
                    <li>
                        <router-link to="">{{ $t('family.jobs.linkList.item3') }}</router-link>
                    </li>
                </ul>
                <div class="overlay"></div>
                <img class="imgHeader" src="image/image-from-rawpixel-id-21755-jpeg.webp" alt="" lazy="loading" />
                <img class="shareIcon" src="image/share.svg" width="100%" @click="hoverShare" />
                <div class="shareList" :class="{ 'slide': slide }">
                    <ul>
                        <li> <a href="http://twitter.com/share?url=https://www.theroyalschool.com/Jobs"
                                class="twitter">Twitter</a></li>
                        <li> <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.theroyalschool.com/Jobs"
                                target="_blank" class="facebook">Facebook</a></li>
                        <li> <a href="mailto:?subject=I wanted you to see this site&amp;body=Check out this site https://www.theroyalschool.com/Jobs"
                                class="email">Email Link</a></li>

                    </ul>
                </div>
            </header>

            <div class="contentPage">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" md="9">
                            <ul class="d-flex flex-column align-start">
                                <li class="titleContent mfont">
                                    {{ $t('family.jobs.contentPage.item1') }}
                                </li>

                                <li class="textContent">
                                    {{ $t('family.jobs.contentPage.item2') }}
                                </li>
                                <li class="titleContent">
                                    {{ $t('family.jobs.contentPage.item3') }}
                                </li>
                                <li class="textContent">
                                    {{ $t('family.jobs.contentPage.item4') }}
                                </li>
                                <li class="textContent">
                                    {{ $t('family.jobs.contentPage.item5') }}
                                </li>
                                <li class="textContent">
                                    {{ $t('family.jobs.contentPage.item6') }}
                                </li>
                                <li class="textContent">
                                    {{ $t('family.jobs.contentPage.item7') }}
                                </li>
                                <!-- form for jobs request-->
                                <br>
                                <form class="d-flex flex-column" method="post">

                                    <ul>

                                        <li>
                                            <label for="select" class="form-label">{{ $t('jobRequest.title1') }}</label>
                                            <br>
                                            <input type="text" v-model="body.Fullname" />
                                            <div class="error" v-if="validFname.length">
                                                <span v-for="error in validFname" :key="error.id">{{ error }}</span>
                                            </div>
                                        </li>

                                        <li class="d-flex flex-row flex-xs-column">
                                            <div>
                                                <label for="select"
                                                    class="form-label">{{ $t('jobRequest.title2') }}</label>
                                                <br>
                                                <select v-model="body.Status" class="mWidth">
                                                    <option value="null" selected>{{ $t('jobRequest.item2') }}</option>
                                                    <option value=" ">{{ $t('jobRequest.item1') }}</option>

                                                </select>

                                                <div class="error" v-if="validStatus.length">
                                                    <span v-for="error in validStatus" :key="error.id">{{ error
                                                    }}</span>
                                                </div>
                                            </div>
                                            <div>
                                                <label for="select"
                                                    class="form-label">{{ $t('jobRequest.title3') }}</label>
                                                <br>
                                                <input type="text" class="mWidth" v-model="body.Age">
                                                <div class="error" v-if="validAge.length">
                                                    <span v-for="error in validAge" :key="error.id">{{ error }}</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <label for="select" class="form-label">{{ $t('jobRequest.title4') }}</label>
                                            <br>
                                            <input type="text" v-model="body.requestJob" />
                                            <div class="error" v-if="validJob.length">
                                                <span v-for="error in validJob" :key="error.id">{{ error }}</span>
                                            </div>
                                        </li>
                                        <li>
                                            <label for="select" class="form-label">{{ $t('jobRequest.title5') }}</label>
                                            <br>
                                            <input type="text" v-model="body.Academic" />
                                            <div class="error" v-if="validAcademic.length">
                                                <span v-for="error in validAcademic" :key="error.id">{{ error }}</span>
                                            </div>
                                        </li>
                                        <li>
                                            <label for="select" class="form-label">{{ $t('jobRequest.title6') }}</label>
                                            <br>
                                            <input type="text" v-model="body.phNumber">
                                            <div class="error" v-if="validPhNumber.length">
                                                <span v-for="error in validPhNumber" :key="error.id">{{ error }}</span>
                                            </div>
                                        </li>
                                        <li>
                                            <label for="select" class="form-label">{{ $t('jobRequest.title7') }}</label>
                                            <br>
                                            <input type="email" v-model="Email" />
                                            <div class="error" v-if="validEmail.length">
                                                <span v-for="error in validEmail" :key="error.id">{{ error }}</span>
                                            </div>
                                        </li>
                                        <li class="d-flex flex-row flex-xs-column">
                                            <div>
                                                <label for="select"
                                                    class="form-label">{{ $t('jobRequest.title8') }}</label>
                                                <br>
                                                <input type="text" class="mWidth" v-model="body.Country">
                                                <div class="error" v-if="validCountry.length">
                                                    <span v-for="error in validCountry" :key="error.id">{{ error
                                                    }}</span>
                                                </div>
                                            </div>
                                            <div>
                                                <label for="select"
                                                    class="form-label">{{ $t('jobRequest.title9') }}</label>
                                                <br>
                                                <input type="text" class="mWidth" v-model="body.Address">
                                                <div class="error" v-if="validAddress.length">
                                                    <span v-for="error in validAddress" :key="error.id">{{ error
                                                    }}</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="uploadFile">
                                                <input type="file" accept=".pdf" @change="handleFile">
                                                <div class="cloud-upload">
                                                    <img src="../image/upload.webp" alt="">
                                                    <div class="Arrow" :class="{ 'trans': trans }">
                                                        <div class="line"></div>
                                                        <div class="line"></div>
                                                        <div class="line"></div>
                                                    </div>

                                                </div>
                                                <p class="textUpload">
                                                    upload cv</p>
                                            </div>
                                            <div class="error" v-if="validFile.length">
                                                <span v-for="error in validFile" :key="error.id">{{ error }}</span>
                                            </div>

                                        </li>

                                        <div>
                                            <br>
                                            <button id="btn" @click.prevent="submitJobs" :class="{ 'active': active }">
                                                <p id="btnText">{{ textBtn }}</p>
                                                <div class="check-box">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                                                        <path fill="transparent" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                                    </svg>
                                                </div>
                                            </button>
                                        </div>

                                    </ul>

                                </form>
                                <!-- /form for jobs request-->
                            </ul>
                        </v-col>
                        <v-col cols="12" md="3" class="d-flex flex-column justify-end">
                            <div class="titlePage">
                                <ul class="d-flex flex-column align-end">
                                    <li>
                                        <router-link to="">{{ $t('family.jobs.titlePage') }}</router-link>
                                    </li>
                                </ul>
                            </div>
                            <div class="navPage">

                                <ul class="d-flex flex-column align-end">
                                    <li class="linkPage">
                                        <router-link :to="`/ParentsSociety`">{{ $t('family.jobs.linkPage.item1') }}
                                        </router-link>
                                    </li>
                                    <li class="linkPage">
                                        <router-link :to="`/Developer`">{{ $t('family.jobs.linkPage.item2') }}
                                        </router-link>
                                    </li>
                                    <li class="linkPage activeLink">
                                        <router-link :to="`/Jobs`">{{ $t('family.jobs.linkPage.item3') }}</router-link>
                                    </li>
                                </ul>
                            </div>
                            <div class="otherLink">
                                <p>{{ $t('family.jobs.otherLink.title') }}</p>
                                <ul class="d-flex flex-column align-end">
                                    <li>
                                        <ul class="d-flex flex-row align-center">
                                            <li>
                                                <router-link :to="`/WelcomeMessage`">
                                                    <p>{{ $t('family.jobs.otherLink.item1') }}</p>
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link :to="`/WelcomeMessage`">
                                                    <div class="img">
                                                        <img src="image/handshake.svg" />
                                                    </div>
                                                </router-link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul class="d-flex flex-row">
                                            <li>
                                                <router-link :to="`/BuildSchool`">
                                                    <p>{{ $t('family.jobs.otherLink.item2') }}</p>
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link :to="`/BuildSchool`">
                                                    <div class="img">
                                                        <img src="image/school-svgrepo-com.svg" />
                                                    </div>
                                                </router-link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul class="d-flex flex-row">
                                            <li>
                                                <router-link :to="`/OurVision`">
                                                    <p>{{ $t('family.jobs.otherLink.item3') }}</p>
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link :to="`/OurVision`">
                                                    <div class="img">
                                                        <img src="image/telescope-svgrepo-com.svg" />
                                                    </div>
                                                </router-link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul class="d-flex flex-row">
                                            <li>
                                                <router-link :to="`/SafetySecurity`">
                                                    <p>{{ $t('family.jobs.otherLink.item4') }}</p>
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link :to="`/SafetySecurity`">
                                                    <div class="img">
                                                        <img src="image/security-camera.svg" />
                                                    </div>
                                                </router-link>
                                            </li>
                                        </ul>

                                    </li>
                                </ul>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </section>
    </div>

</template>

<script>
    import axios from "axios";
    export default {
        data() {
            return {
                textBtn: "Submit",
                slide: null,
                active: false,
                trans: false,
                validFname: [],
                validStatus: [],
                validAge: [],
                validPhNumber: [],
                validJob: [],
                validEmail: [],
                validAcademic: [],
                validCountry: [],
                validAddress: [],
                validFile: [],
                users: [],

                file: '',
                Email: null,
                body: {
                    Fullname: null,
                    Status: null,
                    Age: '',
                    requestJob: null,
                    Academic: null,
                    phNumber: '',
                    Country: null,
                    Address: null,
                }
            }
        },
        methods: {
            hoverShare() {
                this.slide = !this.slide;
            },
            handleFile(e) {
                let file = e.target.files[0];
                // console.log(file);
                let reader = new FileReader();
                reader.onloadend = () => {
                    this.file = reader.result;
                }
                reader.readAsDataURL(file);
                this.trans = true;
            },
            async submitJobs(e) {
                var textWord =
                    /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_]*$/;
                var re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
                var maxnum = 11;
                var minAge = 2;
                var regex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/.?]+/;

                this.validFname = [];
                this.validStatus = [];
                this.validNumber = [];
                this.validJob = [];
                this.validAcademic = [];
                this.validPhNumber = [];
                this.validAge = [];
                this.validEmail = [];
                this.validCountry = [];
                this.validAddress = [];
                this.validFile = [];


                if (this.body.Fullname === null) {
                    this.validFname.push('This field is required *');
                } else if (this.body.Fullname === '') {
                    this.validFname.push('The field is empty, please enter your full name * ');
                } else if (!textWord.test(this.body.Fullname)) {
                    this.validFname.push('The field must contain only alphanumeric characters (a-z) *');
                } else if (this.body.Status === null) {
                    this.validStatus.push('This field is required * ');
                } else if (this.body.Age === null) {
                    this.validAge.push('This field is required *');
                } else if (this.body.Age === '') {
                    this.validAge.push('This field is required * ');
                } else if (textWord.test(this.body.Age)) {
                    this.validAge.push('The field must contain numbers *');
                } else if (((this.body.Age).length > minAge)) {
                    this.validAge.push('* Error');
                } else if (regex.test(this.body.Age)) {
                    this.validAge.push("The entry contains special characters (/[!@#$%^&*()_+={};']:,.<>?) *");
                } else if (this.body.requestJob === null) {
                    this.validJob.push('This field is required *');
                } else if (this.body.requestJob === '') {
                    this.validJob.push('The field is empty, please enter the required job *');
                } else if (!textWord.test(this.body.requestJob)) {
                    this.validJob.push('The field must contain only alphanumeric characters (a-z) *');
                } else if (this.body.Academic === null) {
                    this.validAcademic.push('This field is required *');
                } else if (this.body.Academic === '') {
                    this.validAcademic.push('The field is empty, please enter your academic achievement *');
                } else if (!textWord.test(this.body.Academic)) {
                    this.validAcademic.push('The field must contain only alphanumeric characters (a-z) *');
                } else if (this.body.phNumber === null) {
                    this.validPhNumber.push('This field is required *');
                } else if (this.body.phNumber === '') {
                    this.validPhNumber.push('This field is required *');
                } else if (((this.body.phNumber).length > maxnum) || ((this.body.phNumber).length < maxnum)) {
                    this.validPhNumber.push('Your phone number is invalid *');
                } else if (this.Email === null) {
                    this.validEmail.push('This field is required *');
                } else if (this.Email === '') {
                    this.validEmail.push('The field is empty, please enter your email *');
                } else if (!re.test(this.Email)) {
                    this.validEmail.push('Please enter a valid email address (example@domain.com) *');
                } else if (this.body.Country === null) {
                    this.validCountry.push('This field is required *');
                } else if (this.body.Country === '') {
                    this.validCountry.push('The field is empty, please enter your country address *');
                } else if (!textWord.test(this.body.Country)) {
                    this.validCountry.push('The field must contain only alphanumeric characters (a-z) *');
                } else if (this.body.Address === null) {
                    this.validAddress.push('This field is required *');
                } else if (this.body.Address === '') {
                    this.validAddress.push('The field is empty, please enter a residential address *');
                } else if (!textWord.test(this.body.Address)) {
                    this.validAddress.push('The field must contain only alphanumeric characters (a-z) *');
                } else if (this.file == null) {
                    this.validFile.push('Please upload your CV *')
                } else if (this.file == '') {
                    this.validFile.push('Please upload your CV *')
                } else {
                    this.textBtn = 'Thanks';
                    this.active = true;

                    axios.post(`https://faya.dev/apiProjections/public/api/contactUs`, {
                        store_id: 3,
                        title: "تقديم وضيفة" + this.body.Fullname,
                        email: this.Email,
                        body: JSON.stringify(this.body),
                        file: this.file

                    }).then(response => {
                        const data = JSON.parse(response.data);
                        this.users.push(data);
                    });
                    if (this.active === true) {
                        this.file = '',
                            this.Email = null,
                            this.body.Fullname = null,
                            this.body.Status = null,
                            this.body.Age = '',
                            this.body.requestJob = null,
                            this.body.Academic = null,
                            this.body.phNumber = '',
                            this.body.Country = null,
                            this.body.Address = null
                    }
                }

                e.preventDefault();
            }


        },
    }

</script>
