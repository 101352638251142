<template>
    <div>
        <section class="pageNews">
            <v-container fluid>
                <v-row>
                    <v-col cols="12">
                        <div class="grids d-flex flex-row flex-wrap justify-center">
                        </div>
                        <br><br>
                    </v-col>
                </v-row>
                <br>
            </v-container>
            <div class="viewNews">
                <div class="closeSlide"><img src="image/closeSlide.svg" /></div>
                <div class="embedSteam" id="embedSteam">

                </div>
                <div class="otherLink">
                    <h1>المنشورات ذات الصلة</h1>
                </div>
            </div>

        </section>
    </div>
</template>

<script>
import $ from 'jquery'
import { popVideoLibrrary } from './../../func.js'

export default {
    data() {
        return {
            options: {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            },
        }
    },
    mounted() {
        $(document).ready(function () {
            var UrlSteam = 'https://faya.dev/apiProjections/public/api/posts?storeId=3&type=steam';
            loadvideo();
            function loadvideo() {
                $.getJSON(UrlSteam, function (data) {
                    var linkUrl = data.data[0].linkUrl;
                    var getId = linkUrl.split('/tv/');
                    var viewـid = getId[getId.length - 1];
                    mainVidSteam(data);
                    popVideoLibrrary(viewـid, '#embedSteam');
                });
            }
            function mainVidSteam(data) {
                $.each(data.data, function (i, data) {
                    var imgvid = data.coverImage;
                    var vid = data.linkUrl;
                    var name = data.name;
                    var subDescription = data.subDescription;
                    var date = data.created_at;
                    const event = new Date(date);
                    const options = {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                    };
                    $('.grids').append(`
                            <article class="flexCard" data-key="${vid}" wow fadeInUp" data-wow-duration='3s' data-wow-delay='1s'>
                                    <img src="${imgvid}"/>
                                    <div class="overlay"></div>
                                    <div class="contentCard">
                                        <div class="d-flex flex-row align-center">
                                            <li>instagram</li>
                                            <li>
                                                <div class="lineCard"
                                                    style="{backgroundColor: '#' + NewsPost.color }"></div>
                                            </li>
                                        </div>
                                        <br><br>
                                        <div class="d-flex flex-column items-center">
                                            <p style="font-size:35px">
                                               ${name}
                                            </p>
                                            <p class="name">
                                              ${subDescription}
                                            </p>
                                            <li>
                                                <div class="dataCard">
                                                    ${event.toLocaleDateString('ar-iq', options)}
                                                </div>
                                            </li>
                                        </div>
                                    </div>
                         
                                <div class="hoverCrad">
                                    <ul class="d-flex flex-column align-center">
                                        <li>عرض المنشور</li>
                                    </ul>
                                </div>
                                </article>
 `)

                    $('.grids').on('click', 'article', function () {
                        var id = $(this).attr('data-key'); // get video
                        popVideoLibrrary(id, '#embedSteam');
                        $(".viewNews").css("visibility", "visible");
                        $(".viewNews").css("transform", "translateX(0px)");
                        $(".viewNews").css("opacity", "1");
                        $("._navleft.sticky").css("display", "none");
                        $("._navright.sticky").css("display", "none");
                        $("._navleft").css("display", "none");
                        $("._navright").css("display", "none");
                    });
                    $('.closeSlide').on('click', function () {
                        $(".viewNews").css("visibility", "hidden");
                        $(".viewNews").css("transform", "translateX(100px)");
                        $(".viewNews").css("opacity", "0");
                        $("._navleft.sticky").css("display", "block");
                        $("._navright.sticky").css("display", "block");
                        $("._navleft").css("display", "block");
                        $("._navright").css("display", "block");
                        $('#embedSteam').empty();
                    });
                });
            }
        });
    }
}
</script>
