<template>
    <div>
        <section class="Pages">
            <header class="pageHeader">
                <div class="sub-TitlePage">{{ $t('family.parentsSociety.subtitlePage') }}</div>
                <ul class="linkList d-flex flex-row">
                    <li>
                        <router-link :to="`/`">{{ $t('family.parentsSociety.linkList.item1') }}</router-link>
                    </li>
                    <li class="line"></li>
                    <li>
                        <router-link to="">{{ $t('family.parentsSociety.linkList.item2') }}</router-link>
                    </li>
                    <li class="line"></li>
                    <li>
                        <router-link to="">{{ $t('family.parentsSociety.linkList.item3') }}</router-link>
                    </li>
                </ul>
                <div class="overlay"></div>
                <img class="imgHeader" src="image/image-from-rawpixel-id-21755-jpeg.webp" alt="" lazy="loading"/>
                <img class="shareIcon" src="image/share.svg" width="100%" @click="hoverShare"/>
                <div class="shareList" :class="{'slide':slide}">
                 <ul>
                <li> <a href="http://twitter.com/share?url=https://www.theroyalschool.com/ParentsSociety"  class="twitter">Twitter</a></li>
                <li> <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.theroyalschool.com/ParentsSociety" target="_blank" class="facebook">Facebook</a></li>
                <li> <a href="mailto:?subject=I wanted you to see this site&amp;body=Check out this site https://www.theroyalschool.com/ParentsSociety" class="email">Email Link</a></li>
            </ul>
                </div>
            </header>
            <div class="contentPage">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" md="9">
                            <ul class="d-flex flex-column align-start">
                                <li class="titleContent mfont">
                                   {{ $t('family.parentsSociety.contentPage.item1') }}
                                </li>
                                <li class="textContent">
                                   {{ $t('family.parentsSociety.contentPage.item2') }}
                                </li>
                                <li class="textContent">
                                   {{ $t('family.parentsSociety.contentPage.item3') }}
                                </li>
                                <li class="textContent">
                                   {{ $t('family.parentsSociety.contentPage.item4') }}
                                </li>
                            </ul>
                        </v-col>
                        <v-col cols="12" md="3" class="d-flex flex-column justify-end">
                            <div class="titlePage">
                                <ul class="d-flex flex-column align-end">
                                    <li>
                                        <router-link to="">{{ $t('family.parentsSociety.titlePage') }}</router-link>
                                    </li>
                                </ul>
                            </div>
                            <div class="navPage">
                      
                                <ul class="d-flex flex-column align-end">
                                    <li class="linkPage activeLink">
                                        <router-link :to="`/parentsSociety`">{{ $t('family.parentsSociety.linkPage.item1') }}</router-link>
                                    </li>
                                    <li class="linkPage">
                                        <router-link  :to="`/Developer`">{{ $t('family.parentsSociety.linkPage.item2') }}</router-link>
                                    </li>
                                    <li class="linkPage">
                                        <router-link  :to="`/Jobs`">{{ $t('family.parentsSociety.linkPage.item3') }}</router-link>
                                    </li>
                                </ul>
                            </div>
                            <div class="otherLink">
                                <p>{{ $t('family.parentsSociety.otherLink.title') }}</p>
                                <ul class="d-flex flex-column align-end">
                                    <li>
                                        <ul class="d-flex flex-row align-center">
                                            <li>
                                                <router-link :to="`/ClubsActivities`">
                                                    <p>{{ $t('family.parentsSociety.otherLink.item1') }}</p>
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link :to="`/ClubsActivities`">
                                                    <div class="img"><img src="image/handshake.svg"/></div>
                                                </router-link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul class="d-flex flex-row">
                                            <li>
                                                <router-link :to="`/Developer`">
                                                    <p>{{ $t('family.parentsSociety.otherLink.item2') }}</p>
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link :to="`/Developer`">
                                                    <div class="img"><img src="image/school-svgrepo-com.svg"/></div>
                                                </router-link>
                                            </li>
                                        </ul>
                                    </li>

                                    <li>
                                        <ul class="d-flex flex-row">
                                            <li>
                                                <router-link  :to="`/parentsSociety`">
                                                    <p>{{ $t('family.parentsSociety.otherLink.item3') }}</p>
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link :to="`/parentsSociety`">
                                                    <div class="img"><img src="image/telescope-svgrepo-com.svg"/></div>
                                                </router-link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul class="d-flex flex-row">
                                            <li>
                                                <router-link :to="`/OurVision`">
                                                    <p>{{ $t('family.parentsSociety.otherLink.item4') }}</p>
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link :to="`/OurVision`">
                                                    <div class="img"><img src="image/security-camera.svg"/></div>
                                                </router-link>
                                            </li>
                                        </ul>

                                    </li>
                                </ul>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </section>
    </div>

</template>
<script>
  export default {
    data() {
      return {
          slide:null
      }
    },
    methods:{
        hoverShare() {
          this.slide = !this.slide; 
        }
    },
  }
</script>
