<template>
    <div class="SectionContact">
        <v-container>
            <v-row>
                <v-col cols="12" class="d-flex justify-center">
                    <div class="sectionTitle">{{ $t('contactUs.sectionTitle') }}</div>
                </v-col>
                <v-col cols="12" class="d-flex justify-center">
                    <ul class="d-flex items-center justify-center content-center">
                        <li>
                            <router-link :to="`/`" >{{ $t('contactUs.home') }}</router-link>
                        </li>
                        <li>
                            <div class="line"></div>
                        </li>
                        <li>
                            <router-link to="">{{ $t('contactUs.sectionTitle') }}</router-link>
                        </li>
                    </ul>
                </v-col>
                <v-col cols="12">
                    <p class="titleContent">{{ $t('contactUs.titleContent') }}</p>
                </v-col>
                <v-col cols="12">
                    <v-container fluid>
                        <v-row>
                            <v-col md="6" sm="12" cols="12" >
                                <div class="content">
                                    <ul class="d-flex flex-wrap justify-center">
                                        <li class="cardContact d-flex flex-column align-start">
                                            
                                            <ul class="d-flex flex-row">
                                                <li class="cardTitle">{{ $t('contactUs.info.item3') }}</li>
                                                <li>{{ $t('contactUs.info.item4') }}</li>
                                            </ul>
                                            <ul class="d-flex flex-row">
                                                <li class="cardTitle">{{ $t('contactUs.info.item5') }}</li>
                                                <li>{{ $t('contactUs.info.item6') }}</li>
                                            </ul>
                                            <ul class="d-flex flex-column">
                                                <li class="cardTitle">{{ $t('contactUs.info.item7') }}</li>
                                                <li>+9647733332656</li>
                                                <li>+9647733332656</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </v-col>
                            
                            <v-col  md="6" sm="12" cols="12">
                                <div class="content">
                                    <ul class="d-flex flex-wrap justify-center">                               
                                        <li class="cardContact d-flex flex-column align-start">
                                           <Form/>
                                        </li>
                                    </ul>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import Form from "@/components/Form.vue";

    export default {
       data() {
           return{
           }

       },
       components: {
             Form
       }
    }
</script>
