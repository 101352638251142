<template>
    <section class="sectionLibrary">
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" class="d-flex justify-end sm-justify-center lineSlant">
                                <router-link to="/Nurser_A" class="card">
                                    <img src="image/222.webp"/>
                                    <div class="overlay"></div>
                                    <ul class="d-flex flex-column justify-center items-center content-center">
                                        <li> nursery-A</li>
                                    </ul>
                                </router-link>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" class="d-flex justify-center mb sm-justify-center lineSlant">
                                <router-link to="/Nurser_B" class="card">
                                    <img src="image/222.webp"/>
                                    <div class="overlay"></div>
                                    <ul class="d-flex flex-column justify-center items-center content-center">
                                        <li>nursery-B</li>
                                    </ul>
                                </router-link>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" class="d-flex justify-start mb sm-justify-center">
                                <router-link to="/Nurser_C" class="card">
                                    <img src="image/222.webp"/>
                                    <img src="image/222.webp"/>
                                    <div class="overlay"></div>
                                    <ul class="d-flex flex-column justify-center items-center content-center">
                                        <li>nursery-C</li>
                                    </ul>
                                </router-link>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
          
        </v-container>
    </section>

</template>
