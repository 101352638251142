<template>
    <div>
        <section class="Pages" id="Pages">
            <header class="pageHeader">
                <div class="sub-TitlePage">{{ $t('abouteUs.buildSchool.subtitlePage') }}</div>
                <ul class="linkList d-flex flex-row">
                    <li>
                        <router-link :to="`/`">{{ $t('abouteUs.buildSchool.linkList.item1') }}</router-link>
                    </li>
                    <li class="line"></li>
                    <li>
                        <router-link to="">{{ $t('abouteUs.buildSchool.linkList.item2') }}</router-link>
                    </li>
                    <li class="line"></li>
                    <li>
                        <router-link to="">{{ $t('abouteUs.buildSchool.linkList.item3') }}</router-link>
                    </li>
                </ul>
                 <div class="overlay"></div>
                <img class="imgHeader" src="image/taylor-wilcox-4nKOEAQaTgA-unsplash.webp" alt="" lazy="loading"/>
                <img class="shareIcon" src="image/share.svg" width="100%" @click="hoverShare"/>
                <div class="shareList" :class="{'slide':slide}">
                 <ul>
                <li> <a href="http://twitter.com/share?url=https://www.theroyalschool.com/BuildSchool"  class="twitter">Twitter</a></li>
                <li> <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.theroyalschool.com/BuildSchool" target="_blank" class="facebook">Facebook</a></li>
                <li> <a href="mailto:?subject=I wanted you to see this site&amp;body=Check out this site https://www.theroyalschool.com/BuildSchool" class="email">Email Link</a></li>
                </ul>
                </div>
            </header>

            <div class="contentPage">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" md="9">
                            <ul class="d-flex flex-column align-start">
                                <li class="titleContent mfont">
                                   {{ $t('abouteUs.buildSchool.contentPage.item1') }}
                                </li>
                                <li class="textContent">
                                    {{ $t('abouteUs.buildSchool.contentPage.item2') }}
                                </li>
                                <li class="textContent">
                                    {{ $t('abouteUs.buildSchool.contentPage.item3') }}
                                </li>
                                <li class="textContent">
                                  {{ $t('abouteUs.buildSchool.contentPage.item4') }}
                                </li>
                                <li class="textContent">
                                   {{ $t('abouteUs.buildSchool.contentPage.item5') }}
                                </li>
                                <li class="textContent">
                                   {{ $t('abouteUs.buildSchool.contentPage.item6') }}
                                </li>
                                <li class="textContent">
                                   {{ $t('abouteUs.buildSchool.contentPage.item7') }}
                                </li>
                                <li class="textContent">
                                   {{ $t('abouteUs.buildSchool.contentPage.item8') }}
                                </li>
                                <li class="textContent">
                                   {{ $t('abouteUs.buildSchool.contentPage.item9') }}
                                </li>
                                <li class="textContent">
                                   {{ $t('abouteUs.buildSchool.contentPage.item10') }}
                                </li>
                                <li class="textContent">
                                   {{ $t('abouteUs.buildSchool.contentPage.item11') }}
                                </li>
                                <li class="textContent">
                                {{ $t('abouteUs.buildSchool.contentPage.item12') }}
                                </li>
                                <li class="textContent">
                                   {{ $t('abouteUs.buildSchool.contentPage.item13') }}
                                </li>
                                <li class="textContent">
                                   {{ $t('abouteUs.buildSchool.contentPage.item14') }}
                                </li>
                                <li class="textContent">
                                   {{ $t('abouteUs.buildSchool.contentPage.item15') }}
                                </li>
                                <li class="textContent">
                                   {{ $t('abouteUs.buildSchool.contentPage.item16') }}
                                </li>
                                <li class="textContent">
                                    {{ $t('abouteUs.buildSchool.contentPage.item17') }}
                                </li>
                            </ul>
                        </v-col>
                        <v-col cols="12" md="3" class="d-flex flex-column justify-end">
                            <div class="titlePage">
                                <ul class="d-flex flex-column align-end">
                                    <li>
                                        <router-link to="">{{ $t('abouteUs.buildSchool.titlePage') }}</router-link>
                                    </li>
                                </ul>
                            </div>
                            <div class="navPage">
                                <ul class="d-flex flex-column align-end">
                                    <li class="linkPage">
                                        <router-link :to="`/WelcomeMessage`">{{ $t('abouteUs.buildSchool.linkPage.item1') }}</router-link>
                                    </li>
                                    <li class="linkPage">
                                        <router-link  :to="`/OurVision`"> {{ $t('abouteUs.buildSchool.linkPage.item2') }}</router-link>
                                    </li>
                                    <li class="linkPage activeLink">
                                        <router-link  :to="`/`">{{ $t('abouteUs.buildSchool.linkPage.item3') }}</router-link>
                                    </li>
                                    <li class="linkPage">
                                        <router-link  :to="`/SafetySecurity`">{{ $t('abouteUs.buildSchool.linkPage.item4') }}</router-link>
                                    </li>
                                </ul>
                            </div>
                            <div class="otherLink">
                                <p>{{ $t('abouteUs.buildSchool.otherLink.title') }}</p>
                                <ul class="d-flex flex-column align-end">
                                    <li>
                                        <ul class="d-flex flex-row">
                                            <li>
                                                <router-link  :to="`/BuildSchool`">
                                                    <p>{{ $t('abouteUs.buildSchool.otherLink.item1') }}</p>
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link  :to="`/BuildSchool`">
                                                    <div class="img size"><img src="image/school-svgrepo-com.webp" />
                                                    </div>
                                                </router-link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul class="d-flex flex-row">
                                            <li>
                                                <router-link  :to="`/AdmissionProcedures`">
                                                    <p>{{ $t('abouteUs.buildSchool.otherLink.item2') }}</p>
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link :to="`/AdmissionProcedures`">
                                                    <div class="img size"><img src="image/clipboard.svg" /></div>
                                                </router-link>
                                            </li>
                                        </ul>
                                    </li>

                                    <li>
                                        <ul class="d-flex flex-row">
                                            <li>
                                                <router-link  :to="`/Steam`">
                                                    <p>{{ $t('abouteUs.buildSchool.otherLink.item3') }}</p>
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link :to="`/Steam`">
                                                    <div class="img size"><img src="image/globalEducation.svg"/></div>
                                                </router-link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul class="d-flex flex-row">
                                            <li>
                                                <router-link :to="`/ClubsActivities`">
                                                    <p>{{ $t('abouteUs.buildSchool.otherLink.item4') }}</p>
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link :to="`/ClubsActivities`">
                                                    <div class="img size"><img src="image/activities.svg" /></div>
                                                </router-link>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </section>
    </div>

</template>
<script>
  export default {
    data() {
      return {
          slide:null
      }
    },
    methods:{
        hoverShare() {
          this.slide = !this.slide; 
        }
    },
  }
</script>
