<template>
    <div>
        <section class="Pages">
            <header class="pageHeader">
                <div class="sub-TitlePage">{{ $t('ourSchool.nurseryKindergarten.subtitlePage') }}</div>
                <ul class="linkList d-flex flex-row">
                    <li>
                        <router-link  :to="`/`">{{ $t('ourSchool.nurseryKindergarten.linkList.item1') }}</router-link>
                    </li>
                    <li class="line"></li>
                    <li>
                        <router-link to="">{{ $t('ourSchool.nurseryKindergarten.linkList.item2') }}</router-link>
                    </li>
                    <li class="line"></li>
                    <li>
                        <router-link to="">{{ $t('ourSchool.nurseryKindergarten.linkList.item3') }}</router-link>
                    </li>
                </ul>
                <div class="overlay"></div>
                <img class="imgHeader" src="image/image-from-rawpixel-id-536240-jpeg.webp" alt="" lazy="loading"/>
                <img class="shareIcon" src="image/share.svg" width="100%" @click="hoverShare"/>
                <div class="shareList" :class="{'slide':slide}">
                 <ul>
                <li><a href="http://twitter.com/share?url=https://www.theroyalschool.com/NurseryKindergarten"  class="twitter">Twitter</a></li>
                <li> <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.theroyalschool.com/NurseryKindergarten" target="_blank" class="facebook">Facebook</a></li>
                <li> <a href="mailto:?subject=I wanted you to see this site&amp;body=Check out this site https://www.theroyalschool.com/NurseryKindergarten" class="email">Email Link</a></li>
                </ul>
                </div>
            </header>
            

            <div class="contentPage">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" md="9">
                            <ul class="d-flex flex-column align-start">
                                <li class="titleContent mfont">
                                    {{ $t('ourSchool.nurseryKindergarten.contentPage.item1') }}
                                </li>
                                <li class="textContent">
                                  {{ $t('ourSchool.nurseryKindergarten.contentPage.item2') }}
                                </li>
                                <ul class="d-flex flex-column">
                                    <li class="textContent">{{ $t('ourSchool.nurseryKindergarten.contentPage.item3') }}</li>
                                    <li class="textContent">{{ $t('ourSchool.nurseryKindergarten.contentPage.item4') }}</li>
                                    <li class="textContent">{{ $t('ourSchool.nurseryKindergarten.contentPage.item5') }}</li>
                                    <li class="textContent">{{ $t('ourSchool.nurseryKindergarten.contentPage.item6') }}</li>
                                </ul>
                                <li class="textContent">
                                    {{ $t('ourSchool.nurseryKindergarten.contentPage.item7') }}
                                </li>
                                <br>
                                <li class="textContent">
                                   {{ $t('ourSchool.nurseryKindergarten.contentPage.item8') }}
                                </li>
                            </ul>
                        </v-col>
                        <v-col cols="12" md="3" class="d-flex flex-column justify-end">
                            <div class="titlePage">
                                <ul class="d-flex flex-column align-end">
                                    <li>
                                        <router-link to="">{{ $t('ourSchool.nurseryKindergarten.titlePage') }}</router-link>
                                    </li>
                                </ul>
                            </div>
                            <div class="navPage">
                                <ul class="d-flex flex-column align-end">
                                    <li class="linkPage">
                                        <router-link  :to="`/PupilsAges`">{{ $t('ourSchool.nurseryKindergarten.linkPage.item1') }}</router-link>
                                    </li>
                                    <li class="linkPage activeLink">
                                        <router-link  :to="`/`">{{ $t('ourSchool.nurseryKindergarten.linkPage.item2') }}</router-link>
                                    </li>
                                    <li class="linkPage">
                                        <router-link  :to="`/Primary`">{{ $t('ourSchool.nurseryKindergarten.linkPage.item3') }}</router-link>
                                    </li>
                                    <li class="linkPage">
                                        <router-link  :to="`/Steam`">{{ $t('ourSchool.nurseryKindergarten.linkPage.item4') }}</router-link>
                                    </li>
                                    <li class="linkPage">
                                        <router-link  :to="`/ClubsActivities`">{{ $t('ourSchool.nurseryKindergarten.linkPage.item5') }}</router-link>
                                    </li>
                                </ul>
                            </div>
                            <div class="otherLink">
                                <p>{{ $t('ourSchool.nurseryKindergarten.otherLink.title') }}</p>
                                <ul class="d-flex flex-column align-end">
                                    <li>
                                        <ul class="d-flex flex-row">
                                            <li>
                                                <router-link  :to="`/nurseryKindergarten`">
                                                    <p>{{ $t('ourSchool.nurseryKindergarten.otherLink.item1') }}</p>
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link :to="`/nurseryKindergarten`">
                                                    <div class="img size"><img src="image/book-open-solid.svg" /></div>
                                                </router-link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul class="d-flex flex-row">
                                            <li>
                                                <router-link :to="`/RegisterFees`">
                                                    <p>{{ $t('ourSchool.nurseryKindergarten.otherLink.item2') }}</p>
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link :to="`/RegisterFees`">
                                                    <div class="img size"><img src="image/payment.svg" /></div>
                                                </router-link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul class="d-flex flex-row">
                                            <li>
                                                <router-link :to="`/Jobs`">
                                                    <p>{{ $t('ourSchool.nurseryKindergarten.otherLink.item3') }}</p>
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link :to="`/Jobs`">
                                                    <div class="img size"><img src="image/resume.svg"/></div>
                                                </router-link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul class="d-flex flex-row">
                                            <li>
                                                <router-link :to="`/ContactUs`">
                                                    <p>{{ $t('ourSchool.nurseryKindergarten.otherLink.item4') }}</p>
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link :to="`/ContactUs`">
                                                    <div class="img size"><img src="image/phone.svg" /></div>
                                                </router-link>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </section>
    </div>

</template>
<script>
  export default {
    data() {
      return {
          slide:null
      }
    },
    methods:{
        hoverShare() {
          this.slide = !this.slide; 
        }
    },
  }
</script>
