<template>
<section class="sectionLibrary">
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" class="d-flex justify-end sm-justify-center lineSlant">
                                <router-link :to="`/GradeOne`" class="card">
                                    <img src="image/1.webp"/>
                                    <div class="overlay"></div>
                                    <ul class="d-flex flex-column justify-center items-center content-center">
                                        <li>{{ $t('library.primary.classes.item1') }}</li>
                                    </ul>
                                </router-link>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" class="d-flex justify-center mb sm-justify-center lineSlant">
                                <router-link  :to="`/GradeTwo`" class="card">
                                    <img src="image/2.webp"/>
                                    <div class="overlay"></div>
                                    <ul class="d-flex flex-column justify-center items-center content-center">
                                         <li>{{ $t('library.primary.classes.item2') }}</li>
                                    </ul>
                                </router-link>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" class="d-flex justify-start mb sm-justify-center">
                                 <router-link  :to="`/GradeThree`" class="card">
                                    <img src="image/3.webp"/>
                                     <img src="image/3.webp"/>
                                    <div class="overlay"></div>
                                    <ul class="d-flex flex-column justify-center items-center content-center">
                                         <li>{{ $t('library.primary.classes.item3') }}</li>
                                    </ul>
                                </router-link>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" class=" d-flex justify-end sm-justify-center lineSlant">
                                <router-link :to="`/GradeFour`" class="card">
                                    <img src="image/4.webp"/>
                                    <div class="overlay"></div>
                                    <ul class="d-flex flex-column justify-center items-center content-center">
                                         <li>{{ $t('library.primary.classes.item4') }}</li>
                                    </ul>
                                </router-link>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" class="d-flex justify-center mb sm-justify-center lineSlant">
                                <router-link :to="`/GradeFive`" class="card">
                                    <img src="image/5.webp"/>
                                    <img src="image/5.webp"/>
                                    <div class="overlay"></div>
                                    <ul class="d-flex flex-column justify-center items-center content-center">
                                        <li>{{ $t('library.primary.classes.item5') }}</li>
                                    </ul>
                                </router-link>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" class="d-flex justify-start mb sm-justify-center">
                                 <router-link  :to="`/GradeSixth`" class="card">
                                    <img src="image/8.jpeg"/>
                                     <img src="image/8.jpeg"/>
                                    <div class="overlay"></div>
                                    <ul class="d-flex flex-column justify-center items-center content-center">
                                         <li>{{ $t('library.primary.classes.item6') }}</li>
                                    </ul>
                                </router-link>
                            </v-col>
                        </v-row>
                        
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

