<template>
  <div>
    <div class="Cards d-flex flex-row flex-wrap md-justify-center justify-center"></div>
    <div class="viewNews">
      <div class="closeSlide"><img src="image/closeSlide.svg" /></div>
      <div class="embed" id="embed"></div>
      <div class="otherLink">
        <h1>{{ $t('posts.title') }}</h1>
        <div class="morePost"></div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { popVideoLibrrary } from './../../func.js'

export default {
  data() {
    return {
      options: {
        year: "numeric",
        month: "long",
        day: "numeric",
      },
    };
  },
  mounted() {
    $(document).ready(function () {
      var urlCard =
        "https://faya.dev/apiProjections/public/api/posts?storeId=3&type=mainNews";
      loadCard();
      function loadCard() {
        $.getJSON(urlCard, function (data) {
          if (data.data[0] == null) return;
          var linkUrl = data.data[0]?.linkUrl;
          var getId = linkUrl?.split("/tv/");
          var viewـid = getId[getId?.length - 1]?.split("/")[0]?.split("?")[0];
          var name_id = data.name;
          popVideoLibrrary(viewـid,'#embed');
          mainVidCard(data);
          morePostCard(name_id)
        });
      }
      function morePostCard(name_id) {
        const apiCard = `https://faya.dev/apiProjections/public/api/posts?storeId=3&type=mainNews&subDescription=${name_id}`;
        $.getJSON(apiCard, function (item) {
          if (item.data[0] == null) return;
          var links = item.data[0]?.linkUrl;
          var getId = links?.split("/tv/");
          var viewـid = getId[getId?.length - 1]?.split("/")[0]?.split("?")[0];
          popVideoLibrrary(viewـid,'#embed');
          getMoreCard(item)
        });
      }
      function getMoreCard(item) {
        $.each(item.data, function (i, elment) {
          var imgvid = elment.coverImage;
          var vid = elment?.linkUrl;
          var names = elment.name;
          var subDescription = elment.subDescription;
          var date = elment.created_at;

          const event = new Date(date);
          const options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          };

          $(".morePost").append(`
                             <article class="CardmorePost wow fadeInUp" data-key="${vid}" data-wow-duration='3s' data-wow-delay='1s'>
                                    <img src="${imgvid}"/>
                                    <div class="overlay"></div>
                                    <div class="contentCard">
                                        <div class="d-flex flex-row align-center">
                                            <li>instagram</li>
                                            <li>
                                                <div class="lineCard"
                                                    style="{backgroundColor: '#' + NewsPost.color }"></div>
                                            </li>
                                        </div>
                                        
                                        <div class="d-flex flex-column items-center">
                                            <p style="font-size:35px">
                                               ${names}
                                            </p>
                                            <p class="name">
                                              ${subDescription}
                                            </p>
                                            <li>
                                                <div class="dataCard">
                                                    ${event.toLocaleDateString(
            "ar-iq",
            options
          )}
                                                </div>
                                            </li>
                                        </div>
                                    </div>
                            <div class="hoverCrad">
                                    <ul class="d-flex flex-column align-center">
                                        <li>عرض المنشور</li>
                                    </ul>
                            </div>
                                
                                </article>
      `);

        });

      }
      $(".morePost").html('');

      function mainVidCard(data) {
        $.each(data.data, function (i, data) {
          var imgvid = data.coverImage;
          var vid = data?.linkUrl;
          var name = data.name;
          var subDescription = data.subDescription;
          var date = data.created_at;
          const event = new Date(date);

          const options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          };

          $(".Cards").append(`
                             <article data-key="${vid}" data-title="${subDescription}" class="cardSection wow fadeInUp" data-wow-duration='3s' data-wow-delay='1s'>
                             <div class="d-grid">
                                    <img src="${imgvid}">
                                    <div class="overlay"></div>
                                    <div class="contentCard">
                                        <div class="d-flex flex-row align-center">
                                            <li>instagram</li>
                                            <li>
                                                <div class="lineCard"
                                                    style="{backgroundColor: '#' + NewsPost.color }"></div>
                                            </li>
                                        </div>
                                        
                                        <div class="d-flex flex-column items-center">
                                            <p style="font-size:35px">
                                               ${name}
                                            </p>
                                            <p class="name">
                                              ${subDescription}
                                            </p>
                                            <li>
                                                <div class="dataCard">
                                                    ${event.toLocaleDateString(
            "ar-iq",
            options
          )}
                                                </div>
                                            </li>
                                        </div>
                                    </div>
                          
                            <div class="hoverCrad">
                                    <ul class="d-flex flex-column align-center">
                                        <li>عرض المنشور</li>
                                    </ul>
                            </div>

                            </div>
                                
                                </article>
 `);

        });

        $('.Cards').each(function () {
          $(this).children('article').slice(0, 6).show();
        });
        $(".Cards").on("click", "article", function () {
          var id = $(this).attr("data-key"); // get video
          var name = $(this).attr("data-title");
          popVideoLibrrary(id,'#embed');
          morePostCard(name);
          $(".viewNews").css("visibility", "visible");
          $(".viewNews").css("transform", "translateX(0px)");
          $(".viewNews").css("opacity", "1");
          $("._navleft.sticky").css("display", "none");
          $("._navright.sticky").css("display", "none");
          $(".logoRoyalschool").css("display", "none");
        });
        $(".morePost").on("click", "article", function () {
          var id = $(this).attr("data-key"); // get video
          popVideoLibrrary(id,'#embed');
        });

        $(".closeSlide").on("click", function () {
          $(".viewNews").css("visibility", "hidden");
          $(".viewNews").css("transform", "translateX(100px)");
          $(".viewNews").css("opacity", "0");
          $("._navleft.sticky").css("display", "block");
          $("._navright.sticky").css("display", "block");
          $(".logoRoyalschool").css("display", "flex");
          $('#embed').empty();
        });
      }
    });
  },
};
</script>

